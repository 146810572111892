import {
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    Box,
    Chip,
    OutlinedInput
} from '@mui/material'
import React, { useEffect, useState } from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { CompanyStatus } from '../../../graphql/generated/autogenerated'

const messages = defineMessages({
    filterLabel: {
        defaultMessage: 'Filters',
        description: 'Label for filters selector'
    },
    showActiveLabel: {
        defaultMessage: 'Active',
        description: 'Label for active companies filter option'
    },
    showTestLabel: {
        defaultMessage: 'Test',
        description: 'Label for test companies filter option'
    },
    showDemoLabel: {
        defaultMessage: 'Demo',
        description: 'Label for demo companies filter option'
    },
    showInactiveLabel: {
        defaultMessage: 'Inactive',
        description: 'Label for inactive companies filter option'
    },
    showProspectLabel: {
        defaultMessage: 'Prospect',
        description: 'Label for prospect companies filter option'
    }
})

interface DebouncedCompanyFilterProps {
    statusFilters: CompanyStatus[]
    setStatusFilters: (filters: CompanyStatus[]) => void
    setLoading: (value: boolean) => void
}

export const DebouncedCompanyFilter: React.FC<DebouncedCompanyFilterProps> = ({
    statusFilters,
    setStatusFilters,
    setLoading
}) => {
    const intl = useIntl()
    const theme = useTheme()

    const [debouncedFilters, setDebouncedFilters] =
        useState<CompanyStatus[]>(statusFilters)

    useEffect(() => {
        setLoading(true)
        const timer = setTimeout(() => {
            setStatusFilters(debouncedFilters)
            setLoading(false)
        }, 500)
        return () => clearTimeout(timer)
    }, [debouncedFilters, setStatusFilters, setLoading])

    const handleFilterChange = (e) => {
        setDebouncedFilters(e.target.value)
    }

    return (
        <FormControl>
            <InputLabel id="demo-multiple-chip-label">
                {intl.formatMessage(messages.filterLabel)}
            </InputLabel>
            <Select
                sx={{
                    '.MuiOutlinedInput-input': {
                        display: 'flex',
                        alignItems: 'center'
                    },
                    minWidth: '100px'
                }}
                labelId="demo-multiple-chip-label"
                id="demo-multiple-chip"
                multiple
                value={debouncedFilters}
                onChange={handleFilterChange}
                input={
                    <OutlinedInput
                        id="select-multiple-chip"
                        label={intl.formatMessage(messages.filterLabel)}
                    />
                }
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip
                                data-testid={`chip-${value}`}
                                key={value}
                                label={value}
                                sx={{
                                    fontSize: '1.5rem',
                                    fontFamily: theme.typography.h6,
                                    textTransform: 'uppercase',
                                    height: '24px'
                                }}
                            />
                        ))}
                    </Box>
                )}
            >
                <MenuItem
                    key={CompanyStatus.Active}
                    value={CompanyStatus.Active}
                >
                    {intl.formatMessage(messages.showActiveLabel)}
                </MenuItem>
                <MenuItem
                    key={CompanyStatus.Inactive}
                    value={CompanyStatus.Inactive}
                >
                    {intl.formatMessage(messages.showInactiveLabel)}
                </MenuItem>
                <MenuItem key={CompanyStatus.Demo} value={CompanyStatus.Demo}>
                    {intl.formatMessage(messages.showDemoLabel)}
                </MenuItem>
                <MenuItem key={CompanyStatus.Test} value={CompanyStatus.Test}>
                    {intl.formatMessage(messages.showTestLabel)}
                </MenuItem>
                <MenuItem
                    key={CompanyStatus.Prospect}
                    value={CompanyStatus.Prospect}
                >
                    {intl.formatMessage(messages.showProspectLabel)}
                </MenuItem>
            </Select>
        </FormControl>
    )
}
