import React from 'react'
import { FeatureFlag } from '../../lists'
import { TableCell, TableRow } from '@mui/material'

export interface FeatureFlagsListItemProps {
    featureFlag: FeatureFlag
}

export const FeatureFlagsListItem: React.FC<FeatureFlagsListItemProps> = ({
    featureFlag
}) => {
    return (
        <TableRow
            data-testid={`table-row-${featureFlag.flagName}`}
            key={featureFlag.flagName}
        >
            <TableCell component="th" scope="row">
                {featureFlag?.featureName}
            </TableCell>
            <TableCell>{featureFlag?.flagName}</TableCell>
            <TableCell>{featureFlag?.enabled?.toString()}</TableCell>
        </TableRow>
    )
}
