import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    Box
} from '@mui/material'
import React from 'react'
import { ApolloError } from '@apollo/client'
import { FormattedMessage } from 'react-intl'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'

interface ModalErrorContentProps {
    title: string
    error: ApolloError
    description?: string
    handleRetry: () => void
}

export const ModalErrorContent: React.FC<ModalErrorContentProps> = ({
    title,
    error,
    description,
    handleRetry
}) => {
    const theme = useTheme()
    return (
        <>
            <DialogTitle
                sx={{ pt: 0, fontSize: '24px' }}
                color={theme.palette.error.main}
            >
                {title}
            </DialogTitle>
            <DialogContent>
                <Stack spacing={2}>
                    {description && (
                        <CoreTypography variant="body1">
                            {description}
                        </CoreTypography>
                    )}
                    <CoreTypography variant="body1">
                        {error.message}
                    </CoreTypography>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Box sx={{ p: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleRetry}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to retry signup method action"
                                defaultMessage="Retry"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            </DialogActions>
        </>
    )
}
