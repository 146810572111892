import * as React from 'react'
import { Box } from '@mui/material'

export interface TabPanelProps {
    children?: React.ReactNode
    selectedTab: string
    tabName: string
}

export const TabPanel = (props: TabPanelProps) => {
    const { children, selectedTab, tabName, ...other } = props

    return (
        <div
            role="tabpanel"
            hidden={selectedTab !== tabName}
            id={`tabpanel-${tabName}`}
            aria-labelledby={`tab-${tabName}`}
            {...other}
        >
            {selectedTab === tabName && <Box>{children}</Box>}
        </div>
    )
}
