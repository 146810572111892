import React from 'react'
import { Grid, CircularProgress } from '@mui/material'

interface LoadingScreenProps {
    fullscreen?: boolean
}

export const LoadingScreen: React.FC<LoadingScreenProps> = ({
    fullscreen = false
}) => {
    return (
        <Grid
            item
            xs={12}
            sx={{
                height: fullscreen ? 'calc(100vh - 265px)' : '100%',
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <CircularProgress />
        </Grid>
    )
}
