import { Grid } from '@mui/material'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { UserSubscription } from '../../../../graphql/generated/autogenerated'
import { ModalButtonVariant } from '../../../../types'
import { Card } from '../../../../components/features'
import { AddSubscriptionModal } from './AddSubscriptionModal/AddSubscriptionModal'
import { ContentPlaceholder } from '../../../../components/elements'
import { SubscriptionList } from '../../../../components/lists'
import { EditSubscriptionModal } from './EditSubscriptionModal/EditSubscriptionModal'

const messages = defineMessages({
    createSubscriptionLabel: {
        defaultMessage: 'Create new subscription',
        description: 'Label for create new subscription button'
    },
    noSubscriptionsLabel: {
        defaultMessage: 'No subscriptions added.',
        description: 'Label for create new button'
    },
    subscriptionLabel: {
        defaultMessage: 'Subscriptions',
        description: 'Label for subscriptions section'
    }
})
interface CreateSubscriptionProps {
    subscriptions: Partial<UserSubscription>[]
    subscriptionError: boolean
}

const CreateSubscriptions: React.FC<CreateSubscriptionProps> = ({
    subscriptions,
    subscriptionError
}) => {
    const intl = useIntl()

    return (
        <>
            <Grid item xs={12}>
                <CoreAlert severity="info">
                    <CoreTypography variant={'body1'}>
                        <FormattedMessage
                            description="description for subscriptions and sign-up codes section"
                            defaultMessage="Companies are required to have one default subscription and one friends and family subscription. You can edit the attributes of these subscriptions below. If your company does not want a friends and family subscription, mark it as disabled via the edit button."
                        />
                    </CoreTypography>
                </CoreAlert>
            </Grid>
            {subscriptionError && (
                <Grid item xs={12}>
                    <CoreTypography variant={'body2'} color={'error'}>
                        <FormattedMessage
                            description="Error message"
                            defaultMessage="Error: One default subscription and one friends and family subscription is required."
                        />
                    </CoreTypography>
                </Grid>
            )}
            <Grid item xs={12} data-testid="create-subscriptions">
                <Card
                    modalButtonVariant={ModalButtonVariant.Add}
                    ModalComponent={AddSubscriptionModal}
                    titleId="subscription-table-header"
                    title={intl.formatMessage(messages.subscriptionLabel)}
                    buttonLabel={intl.formatMessage(
                        messages.createSubscriptionLabel
                    )}
                    trigger={{ subscriptions: subscriptions }}
                >
                    {!subscriptions?.length ? (
                        <ContentPlaceholder
                            message={intl.formatMessage(
                                messages.noSubscriptionsLabel
                            )}
                        />
                    ) : (
                        <SubscriptionList
                            subscriptions={subscriptions}
                            editModalComponent={EditSubscriptionModal}
                            showId={false}
                        />
                    )}
                </Card>
            </Grid>
        </>
    )
}

export default CreateSubscriptions
