import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SignupCodeListItem } from '../../list-items'
import { SignupCode } from '../../../graphql/generated/autogenerated'
import { ModalButtonProps } from '../../buttons/ModalButton/ModalButton'
import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material'

export interface SignupCodesListProps {
    companyName?: string
    signupCodes?: Partial<SignupCode>[]
    EditModal?: React.FC<ModalButtonProps>
    shouldLink?: boolean
}

export const SignupCodesList: React.FC<SignupCodesListProps> = ({
    companyName = null,
    signupCodes,
    EditModal,
    shouldLink = false
}) => {
    return (
        <Table
            data-testid="signupcode-list"
            aria-labelledby="signnup-code-table-header"
        >
            <TableHead>
                <TableRow>
                    <TableCell>
                        <FormattedMessage
                            description="Label for signup code name"
                            defaultMessage="Name"
                        />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage
                            description="Label for date a signup code starts"
                            defaultMessage="Start date"
                        />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage
                            description="Label for date a signup code ends"
                            defaultMessage="End date"
                        />
                    </TableCell>
                    <TableCell></TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {signupCodes.map((code) => (
                    <SignupCodeListItem
                        companyName={companyName}
                        item={code}
                        key={code.code}
                        shouldLink={shouldLink}
                        EditModal={EditModal}
                    />
                ))}
            </TableBody>
        </Table>
    )
}
