import { Stack, TextField } from '@mui/material'
import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useFormContext, Controller } from 'react-hook-form'

const messages = defineMessages({
    orgIdInputLabel: {
        defaultMessage: 'Webex Org Id',
        description: 'Label for webex org ID input'
    },
    orgIdRequiredLabel: {
        defaultMessage: 'Webex Org Id is required.',
        description: 'Label for webex org ID required error'
    },
    orgIdInvalidLabel: {
        defaultMessage: 'Invalid Webex Org Id.',
        description: 'Label for webex org ID required error'
    }
} as const)

export type WebexConfigurationFormInputs = {
    orgId: string
}

export const WebexConfigurationInput: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext<WebexConfigurationFormInputs>()

    return (
        <Stack spacing={2}>
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        spellCheck="false"
                        id="webex-org-id"
                        variant="outlined"
                        inputRef={field.ref}
                        value={field.value}
                        error={!!errors?.orgId}
                        onChange={field.onChange}
                        label={intl.formatMessage(messages.orgIdInputLabel)}
                        helperText={errors?.orgId?.message}
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                control={control}
                rules={{
                    required: intl.formatMessage(messages.orgIdRequiredLabel),
                    validate: {
                        noWhiteSpace: (value) => {
                            if (!value.trim())
                                return intl.formatMessage(
                                    messages.orgIdInvalidLabel
                                )
                        }
                    }
                }}
                name="orgId"
            />
        </Stack>
    )
}

export default React.memo(WebexConfigurationInput)
