import {
    Box,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material'
import React from 'react'
import {
    editSubscription,
    removeSubscription
} from '../../../../../slices/createCompany'
import { FormattedMessage } from 'react-intl'
import { ModalProps } from '../../../../../components/buttons'
import { useAppDispatch } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import {
    SubscriptionInput,
    SubscriptionFormInputs
} from '../../../../../components/inputs/SubscriptionInput/SubscriptionInput'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { UserSubscription } from '../../../../../graphql/generated/autogenerated'

export const EditSubscriptionModal: React.FC<ModalProps> = ({
    closeModal,
    trigger
}) => {
    const dispatch = useAppDispatch()
    const { subscription, subscriptions } = trigger

    const defaultValues = {
        name: subscription.internalDescription,
        subscriptionType: subscription.subscriptionType,
        license: subscription.license,
        enabled: Number(subscription.enabled),
        isDefault: subscription.isDefault
    }

    const formMethods = useForm<SubscriptionFormInputs>({
        defaultValues: defaultValues,
        reValidateMode: 'onSubmit'
    })

    const getAlreadyExists = (value) => {
        return (
            value !== defaultValues.name &&
            !!subscriptions.find((sub) => sub.internalDescription === value)
        )
    }

    const handleDeleteSubscriptionClick = () => {
        dispatch(removeSubscription(subscription.id))
        closeModal()
    }

    const handleEditSubscriptionClick: SubmitHandler<
        SubscriptionFormInputs
    > = async (data) => {
        const updatedSubscription = {
            id: subscription.id,
            internalDescription: data.name.trim(),
            isDefault: data.isDefault,
            subscriptionType: data.subscriptionType,
            license: data.license,
            enabled: Boolean(data.enabled)
        } as Partial<UserSubscription>
        dispatch(editSubscription(updatedSubscription))
        closeModal()
    }

    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>
                <FormattedMessage
                    defaultMessage="Edit Subscription"
                    description="Title for edit subscription modal"
                />
            </DialogTitle>
            <DialogContent>
                <FormProvider {...formMethods}>
                    <Box
                        component="form"
                        id="edit-subscription-form"
                        noValidate
                    >
                        <SubscriptionInput
                            canSetDefault={true}
                            getAlreadyExists={getAlreadyExists}
                        />
                    </Box>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Box sx={{ p: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteSubscriptionClick}
                        sx={{ mr: 2 }}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to delete subscription"
                                defaultMessage="Delete"
                            />
                        </CoreTypography>
                    </Button>
                    <Button
                        form="edit-subscription-form"
                        variant="contained"
                        color="primary"
                        onClick={formMethods.handleSubmit(
                            handleEditSubscriptionClick
                        )}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to save subscription"
                                defaultMessage="Save"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            </DialogActions>
        </>
    )
}
