import {
    Box,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack
} from '@mui/material'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { LogoInput } from '../../../../../components/inputs'
import { ModalProps } from '../../../../../components/buttons'
import { useAppDispatch } from '@thriveglobal/thrive-web-core'
import BrandInput, {
    BrandFormInputs
} from '../../../../../components/inputs/BrandInput/BrandInput'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { editBrand, removeBrand } from '../../../../../slices/createCompany'

export const EditBrandModal: React.FC<ModalProps> = ({
    closeModal,
    trigger
}) => {
    const dispatch = useAppDispatch()
    const { brand, brandList } = trigger
    const [logoUrl, setLogoUrl] = useState(brand.imageUrl)

    const defaultValues = {
        name: brand.name,
        imageUrl: brand.imageUrl,
        // Reverse checkbox value since hide = checkbox: true, isPublic:false
        // and show = checkbox:false, isPublic:true
        isPublic: !brand.isPublic
    }

    const handleEditBrandClick: SubmitHandler<BrandFormInputs> = async (
        data,
        event
    ) => {
        const newBrand = {
            originalName: brand.name,
            brand: {
                name: data.name.trim(),
                imageUrl: logoUrl || null,
                // Reverse checkbox value since hide = checkbox: true, isPublic:false
                // and show = checkbox:false, isPublic:true
                isPublic: !data.isPublic
            }
        }
        dispatch(editBrand(newBrand))
        closeModal && closeModal()
    }

    const getAlreadyExists = (value) => {
        return (
            value !== defaultValues.name &&
            !!brandList.find((el) => el.name === value)
        )
    }

    const handleDeleteBrandClick = () => {
        dispatch(removeBrand(brand.name))
        closeModal && closeModal()
    }

    const formMethods = useForm<BrandFormInputs>({
        defaultValues: defaultValues,
        reValidateMode: 'onSubmit'
    })
    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>
                <FormattedMessage
                    defaultMessage="Edit brand"
                    description="Title for edit brand modal"
                />
            </DialogTitle>
            <DialogContent>
                <Stack
                    spacing={2}
                    component="form"
                    id="edit-brand-form"
                    noValidate
                >
                    <FormProvider {...formMethods}>
                        <LogoInput
                            type="brand"
                            logoUrl={logoUrl}
                            setLogoUrl={setLogoUrl}
                        />
                        <BrandInput
                            getAlreadyExists={getAlreadyExists}
                            fullsize
                        />
                    </FormProvider>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Box sx={{ p: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteBrandClick}
                        sx={{ mr: 2 }}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to delete brand"
                                defaultMessage="Delete"
                            />
                        </CoreTypography>
                    </Button>
                    <Button
                        form="edit-brand-code-form"
                        variant="contained"
                        color="primary"
                        onClick={formMethods.handleSubmit(handleEditBrandClick)}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to save subscription"
                                defaultMessage="Save"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            </DialogActions>
        </>
    )
}
