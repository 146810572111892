import React from 'react'
import { Stack } from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'

export interface ContentPlaceholderProps {
    message: string
    button?: React.ReactNode
}

export const ContentPlaceholder: React.FC<ContentPlaceholderProps> = ({
    message,
    button
}) => {
    const theme = useTheme()
    return (
        <Stack
            direction="column"
            spacing={1}
            sx={{
                p: 2,
                width: '100%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                backgroundColor: theme.palette.grey[100]
            }}
        >
            <CoreTypography variant="body1">{message}</CoreTypography>
            {button}
        </Stack>
    )
}
