import {
    Box,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { getDates } from '../../../../../utils'
import { ModalProps } from '../../../../../components/buttons'
import { useAppDispatch } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { addSignupCode } from '../../../../../slices/createCompany'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import {
    SignupCodeInput,
    SignupCodeFormInputs
} from '../../../../../components/inputs/SignupCodeInput/SignupCodeInput'

export const AddSignupCodeModal: React.FC<ModalProps> = ({
    closeModal,
    trigger
}) => {
    const dispatch = useAppDispatch()
    const dates = getDates()
    const { subscriptionId, signupCodes } = trigger

    const defaultValues = {
        code: '',
        startDate: dates.today,
        endDate: dates.yearFromToday
    }

    const formMethods = useForm<SignupCodeFormInputs>({
        defaultValues: defaultValues,
        reValidateMode: 'onSubmit'
    })

    const getAlreadyExists = (value) => {
        return !!signupCodes.find((el) => el.code === value)
    }

    const handleAddSignupCodeClick: SubmitHandler<
        SignupCodeFormInputs
    > = async (data) => {
        const newCode = {
            subscriptionId: subscriptionId,
            code: data.code.trim(),
            validFrom: data.startDate,
            validUntil: data.endDate
        }
        dispatch(addSignupCode(newCode))
        closeModal()
    }

    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>
                <FormattedMessage
                    defaultMessage="Add sign-up code"
                    description="Title for add new signup code modal"
                />
            </DialogTitle>
            <DialogContent>
                <Box component="form" id="add-signup-code-form" noValidate>
                    <FormProvider {...formMethods}>
                        <SignupCodeInput getAlreadyExists={getAlreadyExists} />
                    </FormProvider>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ p: 2 }}>
                    <Button
                        form="add-signup-code-form"
                        variant="contained"
                        color="primary"
                        onClick={formMethods.handleSubmit(
                            handleAddSignupCodeClick
                        )}
                        sx={{ ml: 2 }}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to add subscription"
                                defaultMessage="Add"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            </DialogActions>
        </>
    )
}
