import React from 'react'
import { Divider, Grid } from '@mui/material'
import { FormattedMessage } from 'react-intl'
import { setCompanyLogo } from '../../../../slices/createCompany'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { LogoInput, CompanyDetailsInput } from '../../../../components/inputs'
import { useAppSelector, useAppDispatch } from '@thriveglobal/thrive-web-core'

const CompanyName: React.FC = () => {
    const dispatch = useAppDispatch()
    const createCompany = useAppSelector((state) => state.createCompany)
    const { imageUrl } = createCompany?.company

    const setLogoUrl = (url: string) => {
        dispatch(setCompanyLogo(url))
    }

    return (
        <>
            <Grid item xs={12} data-testid="company-name-section">
                <CoreTypography variant={'h3'}>
                    <FormattedMessage
                        description="Heading for company details section"
                        defaultMessage="Company"
                    />
                </CoreTypography>
            </Grid>
            <Grid item xs={12} md={7}>
                <LogoInput
                    logoUrl={imageUrl}
                    type="company"
                    setLogoUrl={setLogoUrl}
                />
            </Grid>
            <CompanyDetailsInput />
            <Grid item xs={12}>
                <Divider />
            </Grid>
        </>
    )
}

export default CompanyName
