import React from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { FormControlLabel, Checkbox } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'

const messages = defineMessages({
    visibilityCheckboxLabel: {
        defaultMessage: 'Hide from company selector on sign-up page',
        description:
            'Label for checkbox to hide company from company select on sign-up page'
    }
})

export const VisibilityCheckbox: React.FC = () => {
    const intl = useIntl()
    const { control } = useFormContext()

    return (
        <>
            <FormControlLabel
                control={
                    <Controller
                        render={({ field }) => (
                            <Checkbox
                                id="company-visibility-input"
                                checked={field.value}
                                inputRef={field.ref}
                                onChange={field.onChange}
                            />
                        )}
                        name="isPublic"
                        control={control}
                    />
                }
                label={intl.formatMessage(messages.visibilityCheckboxLabel)}
            />
        </>
    )
}
