import React, { ReactNode } from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import {
    InputLabel,
    FormControl,
    MenuItem,
    Select,
    FormHelperText
} from '@mui/material'
import { SubscriptionType } from '../../../graphql/generated/autogenerated'

const messages = defineMessages({
    subscriptionTypeLabel: {
        defaultMessage: 'Subscription type',
        description: 'Label for type field'
    },
    employeeTypeLabel: {
        defaultMessage: 'Employee',
        description: 'Label for employee subscription type option'
    },
    friendsAndFamilyTypeLabel: {
        defaultMessage: 'Friends and family',
        description: 'Label for friends and family subscription type option'
    },
    otherTypeLabel: {
        defaultMessage: 'Other',
        description: 'Label for other subscription type option'
    },
    subscriptionTypeRequiredLabel: {
        defaultMessage: 'Subscription type is required.',
        description: 'Label for subscription type required error'
    }
})

export const SubscriptionTypeSelector: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()
    return (
        <>
            <FormControl
                variant="outlined"
                fullWidth
                error={!!errors?.subscriptionType}
                required
            >
                <InputLabel id="subType-select-label">
                    {intl.formatMessage(messages.subscriptionTypeLabel)}
                </InputLabel>
                <Controller
                    render={({ field }) => (
                        <Select
                            fullWidth
                            labelId="subType-select-label"
                            id="subType-select"
                            value={field.value}
                            inputRef={field.ref}
                            onChange={field.onChange}
                            label={intl.formatMessage(
                                messages.subscriptionTypeLabel
                            )}
                        >
                            <MenuItem
                                value={SubscriptionType.Employee}
                                aria-label={intl.formatMessage(
                                    messages.employeeTypeLabel
                                )}
                            >
                                {intl.formatMessage(messages.employeeTypeLabel)}
                            </MenuItem>
                            <MenuItem
                                value={SubscriptionType.FriendsAndFamily}
                                aria-label={intl.formatMessage(
                                    messages.friendsAndFamilyTypeLabel
                                )}
                            >
                                {intl.formatMessage(
                                    messages.friendsAndFamilyTypeLabel
                                )}
                            </MenuItem>
                            <MenuItem
                                value={SubscriptionType.Other}
                                aria-label={intl.formatMessage(
                                    messages.otherTypeLabel
                                )}
                            >
                                {intl.formatMessage(messages.otherTypeLabel)}
                            </MenuItem>
                        </Select>
                    )}
                    name="subscriptionType"
                    control={control}
                    rules={{
                        required: intl.formatMessage(
                            messages.subscriptionTypeRequiredLabel
                        )
                    }}
                />
                <FormHelperText variant="standard">
                    <>{errors?.subscriptionType?.message}</>
                </FormHelperText>
            </FormControl>
        </>
    )
}
