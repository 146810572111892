import React, { useMemo, useState } from 'react'
import { Grid } from '@mui/material'
import { FormattedMessage, useIntl } from 'react-intl'
import { setActiveTab } from '../../../slices/createCompany'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import CreateSignupCodes from './CreateSignupCodes/CreateSignupCodes'
import CreateSubscriptions from './CreateSubscriptions/CreateSubscriptions'
import { useAppSelector, useAppDispatch } from '@thriveglobal/thrive-web-core'
import { CreateCompanyTabs } from '../CreateCompany'
import { TabBarNav } from '../../../components/tabs'
import {
    SignupCode,
    SubscriptionType,
    UserSubscription
} from '../../../graphql/generated/autogenerated'
import { SubscriptionSelector } from './CreateSignupCodes/SubscriptionSelector/SubscriptionSelector'

const hasDefaultSubscription = (
    subscriptions: Partial<UserSubscription>[]
): boolean => {
    return !!subscriptions?.find((sub) => sub.isDefault)
}

const hasFriendsAndFamilySubscription = (
    subscriptions: Partial<UserSubscription>[]
): boolean => {
    return !!subscriptions?.find(
        (sub) => sub.subscriptionType === SubscriptionType.FriendsAndFamily
    )
}

const hasFriendsAndFamilyGroupCode = (
    subscriptions: Partial<UserSubscription>[],
    signupCodes: Partial<SignupCode>[]
): boolean => {
    const sub = subscriptions?.find(
        (sub) => sub.subscriptionType === SubscriptionType.FriendsAndFamily
    )
    if (!sub) {
        return false
    } else {
        return !!signupCodes?.find((code) => code.subscriptionId === sub.id)
    }
}

const SubscriptionsAndCodes: React.FC = () => {
    const dispatch = useAppDispatch()

    const [subscriptionError, setSubscriptionError] = useState<boolean>(false)
    const [groupCodeError, setGroupCodeError] = useState<boolean>(false)

    const createCompany = useAppSelector((state) => state.createCompany)
    const { selectedSubscriptionId, signupCodes, subscriptions } = createCompany
    const selectedSubscription = subscriptions.find(
        (sub) => sub.id === selectedSubscriptionId
    )
    const groupcodes = signupCodes.filter(
        (code) => code.subscriptionId === selectedSubscriptionId
    )

    const canContinue = useMemo(() => {
        //one default and one friends and family subscription is required
        //friends and family subscription must have a group code
        const hasRequiredSubscriptions =
            subscriptions?.length > 0 &&
            hasDefaultSubscription(subscriptions) &&
            hasFriendsAndFamilySubscription(subscriptions)
        const hasRequiredGroupCode = hasFriendsAndFamilyGroupCode(
            subscriptions,
            signupCodes
        )
        if (hasRequiredSubscriptions) {
            setSubscriptionError(false)
        }
        if (hasRequiredGroupCode) {
            setGroupCodeError(false)
        }
        return { hasRequiredSubscriptions, hasRequiredGroupCode }
    }, [subscriptions, signupCodes])

    const handleNextClick = () => {
        if (
            canContinue.hasRequiredSubscriptions &&
            canContinue.hasRequiredGroupCode
        ) {
            setSubscriptionError(false)
            setGroupCodeError(false)
            dispatch(setActiveTab(CreateCompanyTabs.EmailDomains))
        } else {
            !canContinue.hasRequiredSubscriptions && setSubscriptionError(true)
            !canContinue.hasRequiredGroupCode && setGroupCodeError(true)
        }
    }

    const handleBackClick = () => {
        dispatch(setActiveTab(CreateCompanyTabs.CompanyAndBrands))
    }

    return (
        <>
            <Grid item xs={12}>
                <CoreTypography variant={'h3'}>
                    <FormattedMessage
                        description="Heading for Subscriptions and sign-up codes section"
                        defaultMessage="Subscriptions and sign-up codes"
                    />
                </CoreTypography>
            </Grid>
            <CreateSubscriptions
                subscriptions={subscriptions}
                subscriptionError={subscriptionError}
            />
            <Grid item xs={12}>
                <CoreAlert severity="warning">
                    <CoreTypography variant={'body1'}>
                        <FormattedMessage
                            description="sign-up codes section"
                            defaultMessage="Please add a sign-up code for the friends and family subscription, even if it is disabled. This will not be visible to users."
                        />
                    </CoreTypography>
                </CoreAlert>
            </Grid>
            {groupCodeError && (
                <Grid item xs={12}>
                    <CoreTypography variant={'body2'} color={'error'}>
                        <FormattedMessage
                            description="group code error message"
                            defaultMessage="Error: A sign-up code is required for the friends and family subscription."
                        />
                    </CoreTypography>
                </Grid>
            )}

            <Grid item xs={12} md={3}>
                <SubscriptionSelector
                    subscriptions={subscriptions}
                    selectedSubscriptionId={selectedSubscriptionId}
                />
            </Grid>
            <Grid item xs={12} md={9}>
                <CreateSignupCodes
                    subscriptionId={selectedSubscriptionId}
                    subscriptionName={selectedSubscription?.internalDescription}
                    signupCodes={groupcodes}
                />
            </Grid>
            <Grid item xs={12}>
                <TabBarNav
                    handleNextClick={handleNextClick}
                    handleBackClick={handleBackClick}
                    isFirstTab={false}
                    isFinalTab={false}
                />
            </Grid>
        </>
    )
}

export default SubscriptionsAndCodes
