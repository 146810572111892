import React, { useEffect, useCallback } from 'react'
import { Box, Grid, Typography } from '@mui/material'
import EmailDomains from './EmailDomains/EmailDomains'
import { resetState } from '../../slices/createCompany'
import { TabBar, TabPanel } from '../../components/tabs'
import { usePermissions } from '../../hooks/usePermissions'
import { defineMessages, FormattedMessage } from 'react-intl'
import CompanyAndBrands from './CompanyAndBrands/CompanyAndBrands'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useAppDispatch, useAppSelector } from '@thriveglobal/thrive-web-core'
import SubscriptionsAndCodes from './SubscriptionsAndCodes/SubscriptionsAndCodes'

const messages = defineMessages({
    companyAndBrandsLabel: {
        defaultMessage: 'Company and Brands',
        description: 'Label for company and brands tab'
    },
    subscriptionsCodesLabel: {
        defaultMessage: 'subscriptions and codes',
        description: 'Label for subscriptions and codes tab'
    },
    roleModelsLabel: {
        defaultMessage: 'role models',
        description: 'Label for role models tab'
    },
    coursesLabel: {
        defaultMessage: 'courses',
        description: 'Label for courses tab'
    },
    journeysLabel: {
        defaultMessage: 'journeys',
        description: 'Label for journeys tab'
    },
    emailDomainsLabel: {
        defaultMessage: 'email domains',
        description: 'Label for email domains tab'
    }
})

export enum CreateCompanyTabs {
    CompanyAndBrands = 'companyandbrands',
    SubscriptionsAndCodes = 'subscriptionscodes',
    EmailDomains = 'emaildomains'
}

export const createCompanyTabOptions = [
    {
        label: messages.companyAndBrandsLabel,
        value: CreateCompanyTabs.CompanyAndBrands,
        panel: <CompanyAndBrands />
    },
    {
        label: messages.subscriptionsCodesLabel,
        value: CreateCompanyTabs.SubscriptionsAndCodes,
        panel: <SubscriptionsAndCodes />
    },
    {
        label: messages.emailDomainsLabel,
        value: CreateCompanyTabs.EmailDomains,
        panel: <EmailDomains />
    }
]

const CreateCompanyPage: React.FC = () => {
    const createCompany = useAppSelector((state) => state.createCompany)
    const { hasRequiredPermissions } = usePermissions()
    const dispatch = useAppDispatch()

    //memoize dispatch so the reference is stable
    const memoizedDispatch = useCallback(
        (args) => {
            dispatch(args)
        },
        [dispatch]
    )

    useEffect(() => {
        return function cleanup() {
            memoizedDispatch(resetState())
        }
    }, [memoizedDispatch])

    const selectedTab =
        createCompany?.activeTab || CreateCompanyTabs.CompanyAndBrands

    return (
        <Grid container p={3}>
            {hasRequiredPermissions ? (
                <>
                    <Grid item xs={12}>
                        <CoreAlert
                            severity="info"
                            variant="standard"
                            sx={{ mb: { xs: 2, sm: 4 } }}
                        >
                            <CoreTypography variant="body1">
                                <FormattedMessage
                                    defaultMessage="All companies are created with default Journeys, Courses, Challenges and Role Model content. Manage content in Strapi."
                                    description="Label for info alert for company creation"
                                />
                            </CoreTypography>
                        </CoreAlert>
                        <Typography variant={'h1'}>
                            <FormattedMessage
                                description="Heading for the Create New Company page in the admin tool"
                                defaultMessage="Create New Company"
                            />
                        </Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <TabBar
                            value={selectedTab}
                            tabs={createCompanyTabOptions}
                            scrollable
                        />
                        <Box sx={{ pt: 3 }}>
                            {createCompanyTabOptions.map((option) => {
                                return (
                                    <Box key={option.value}>
                                        <TabPanel
                                            selectedTab={selectedTab}
                                            tabName={option.value}
                                            key={option.value}
                                        >
                                            <Grid container sx={{ pt: 1 }}>
                                                {option.panel}
                                            </Grid>
                                        </TabPanel>
                                    </Box>
                                )
                            })}
                        </Box>
                    </Grid>
                </>
            ) : (
                <Grid item xs={12}>
                    <CoreAlert severity="error">
                        <FormattedMessage
                            description="Error message shown if user is not authorized to view page"
                            defaultMessage="You are not authorized to view this page."
                        />
                    </CoreAlert>
                </Grid>
            )}
        </Grid>
    )
}

export default CreateCompanyPage
