import React from 'react'
import { TableCell, TableRow } from '@mui/material'
import { ModalButtonVariant } from '../../../types'
import { useIntl, defineMessages } from 'react-intl'
import { ModalButton, ModalProps } from '../../buttons'
import { UserSubscription } from '../../../graphql/generated/autogenerated'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'

const messages = defineMessages({
    editLabel: {
        defaultMessage: 'edit subscription',
        description: 'Aria label to describe edit subscription button'
    },
    trueDescriptor: {
        defaultMessage: 'true',
        description: 'Text to descibe that a value is true'
    },
    falseDescriptor: {
        defaultMessage: 'false',
        description: 'Text to descibe that a value is false'
    },
    enabledDescriptor: {
        defaultMessage: 'Enabled',
        description: 'Text to descibe that a value is enabled'
    },
    disabledDescriptor: {
        defaultMessage: 'Disabled',
        description: 'Text to descibe that a value is disabled'
    }
})

export interface SubscriptionListItemProps {
    item: Partial<UserSubscription>
    subscriptions: Partial<UserSubscription>[]
    editModalComponent: React.FC<ModalProps>
    showId?: boolean
}

export const SubscriptionListItem: React.FC<SubscriptionListItemProps> = ({
    item,
    subscriptions,
    editModalComponent,
    showId = true
}) => {
    const intl = useIntl()
    const theme = useTheme()
    return (
        <TableRow
            data-testid={`table-row-${item.id}`}
            key={item.id}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0
                }
            }}
        >
            <TableCell component="th" scope="code">
                {item.internalDescription || 'N/A'}
            </TableCell>
            {showId && <TableCell>{item.id}</TableCell>}
            <TableCell>{item.subscriptionType}</TableCell>
            <TableCell>{item.license}</TableCell>
            <TableCell>
                {item.isDefault
                    ? intl.formatMessage(messages.trueDescriptor)
                    : intl.formatMessage(messages.falseDescriptor)}
            </TableCell>
            <TableCell>
                <CoreTypography
                    variant="overline"
                    sx={{
                        color: item?.enabled
                            ? 'green'
                            : theme.palette.error.main
                    }}
                >
                    {item?.enabled
                        ? intl.formatMessage(messages.enabledDescriptor)
                        : intl.formatMessage(messages.disabledDescriptor)}
                </CoreTypography>
            </TableCell>
            <TableCell>
                <ModalButton
                    modalButtonVariant={ModalButtonVariant.Icon}
                    buttonLabel={intl.formatMessage(messages.editLabel)}
                    iconName={'pen'}
                    ModalComponent={editModalComponent}
                    trigger={{
                        subscription: item,
                        allSubscriptions: subscriptions
                    }}
                />
            </TableCell>
        </TableRow>
    )
}
