import React, { useState } from 'react'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import {
    Box,
    Breakpoint,
    Button,
    ButtonProps,
    Dialog,
    IconButton
} from '@mui/material'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { ModalButtonVariant } from '../../../types'
import { IconName } from '@fortawesome/fontawesome-common-types'

const messages = defineMessages({
    closeModalLabel: {
        defaultMessage: 'Close modal',
        description: 'Label for button to close modal'
    }
})

export interface ModalProps {
    closeModal?: () => void
    trigger?: any
}

export interface ModalButtonProps extends ButtonProps {
    modalButtonVariant: ModalButtonVariant
    buttonLabel?: string
    ModalComponent?: React.FC<ModalProps>
    modalSize?: Breakpoint
    iconName?: IconName
    buttonText?: string
    trigger?: any
}

export const ModalButton: React.FC<ModalButtonProps> = ({
    modalButtonVariant,
    buttonLabel,
    buttonText,
    ModalComponent,
    modalSize,
    iconName,
    trigger,
    size = 'large',
    color = 'primary',
    variant = 'text',
    ...buttonProps
}) => {
    const intl = useIntl()
    const [modalOpen, setModalOpen] = useState(false)

    const handleClick = () => {
        setModalOpen(true)
    }

    const handleClose = () => {
        setModalOpen(false)
    }

    return (
        <>
            {modalButtonVariant === ModalButtonVariant.Default && (
                <Box>
                    <Button
                        size={size}
                        variant={variant}
                        color={color}
                        {...buttonProps}
                        onClick={handleClick}
                        aria-label={buttonLabel}
                        startIcon={
                            iconName && (
                                <LeafIcon icon={iconName} fontSize={'small'} />
                            )
                        }
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {buttonText}
                        </CoreTypography>
                    </Button>
                </Box>
            )}
            {modalButtonVariant === ModalButtonVariant.Edit && (
                <Box>
                    <Button
                        size={size}
                        variant={variant}
                        color={color}
                        {...buttonProps}
                        onClick={handleClick}
                        aria-label={buttonLabel}
                        startIcon={<LeafIcon icon={'pen'} fontSize={'small'} />}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {buttonText ? (
                                buttonText
                            ) : (
                                <FormattedMessage
                                    description="Label for edit button"
                                    defaultMessage="Edit"
                                />
                            )}
                        </CoreTypography>
                    </Button>
                </Box>
            )}
            {modalButtonVariant === ModalButtonVariant.Add && (
                <Box>
                    <Button
                        size={size}
                        variant={variant}
                        color={color}
                        {...buttonProps}
                        startIcon={
                            <LeafIcon icon={'plus'} fontSize={'small'} />
                        }
                        onClick={handleClick}
                        aria-label={buttonLabel}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {buttonText ? (
                                buttonText
                            ) : (
                                <FormattedMessage
                                    description="Label for add button"
                                    defaultMessage="Add"
                                />
                            )}
                        </CoreTypography>
                    </Button>
                </Box>
            )}
            {modalButtonVariant === ModalButtonVariant.Settings && (
                <Box>
                    <Button
                        size={size}
                        variant={variant}
                        color={color}
                        {...buttonProps}
                        startIcon={
                            <LeafIcon icon={'gear'} fontSize={'small'} />
                        }
                        onClick={handleClick}
                        aria-label={buttonLabel}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for Settings button"
                                defaultMessage="Settings"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            )}
            {modalButtonVariant === ModalButtonVariant.Icon && (
                <Box>
                    <IconButton
                        aria-label={buttonLabel}
                        color={color}
                        onClick={handleClick}
                    >
                        <LeafIcon icon={iconName} fontSize="small" />
                    </IconButton>
                </Box>
            )}
            <Dialog
                onClose={handleClose}
                open={modalOpen}
                fullWidth={true}
                maxWidth={modalSize ? modalSize : 'xs'}
                sx={{ '.MuiDialogContent-root': { pt: '1rem !important' } }}
            >
                <Box
                    sx={{
                        display: 'flex',
                        m: 2,
                        mb: 0,
                        justifyContent: 'end'
                    }}
                >
                    <IconButton
                        color={color}
                        aria-label={intl.formatMessage(
                            messages.closeModalLabel
                        )}
                        onClick={handleClose}
                    >
                        <LeafIcon icon={'xmark'} />
                    </IconButton>
                </Box>
                {ModalComponent ? (
                    <ModalComponent
                        closeModal={handleClose}
                        trigger={trigger}
                    />
                ) : null}
            </Dialog>
        </>
    )
}
