import React, { ReactNode } from 'react'
import { TextField } from '@mui/material'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'

const messages = defineMessages({
    idpHintRequiredError: {
        defaultMessage: 'Idp Hint is required.',
        description: 'Error text for required idp hint input'
    },
    ipdHintHelperText: {
        defaultMessage: 'Enter Idp Hint to enable Keycloak SSO',
        description: 'Helper text for sso idp hint input'
    },
    idpHintInputLabel: {
        defaultMessage: 'Idp Hint',
        description: 'Label for sso idp hint input'
    }
})

export interface IdpHintFormInputs {
    idpHint: string
}

export const IdpHintInput: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <>
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        spellCheck="false"
                        id="idp-id"
                        variant="outlined"
                        inputRef={field.ref}
                        value={field.value}
                        error={!!errors?.idpHint}
                        onChange={field.onChange}
                        label={intl.formatMessage(messages.idpHintInputLabel)}
                        helperText={
                            <>
                                {errors?.idpHint?.message
                                    ? errors?.idpHint?.message
                                    : intl.formatMessage(
                                          messages.ipdHintHelperText
                                      )}
                            </>
                        }
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                control={control}
                rules={{
                    required: intl.formatMessage(messages.idpHintRequiredError),
                    validate: {
                        notJustWhiteSpace: (value) => {
                            if (!value.trim())
                                return intl.formatMessage(
                                    messages.idpHintRequiredError
                                )
                        }
                    }
                }}
                name="idpHint"
            />
        </>
    )
}
