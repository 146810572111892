import { Apollo, useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    AlertTitle,
    Box,
    Button,
    CircularProgress,
    Grid,
    Stack
} from '@mui/material'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import React, { useCallback, useEffect, useState } from 'react'
import { defineMessages, FormattedMessage, useIntl } from 'react-intl'
import { useHistory } from 'react-router-dom'
import {
    IdpType,
    LoginMethod,
    useAddChallengesToSubscriptionMutation,
    useCreateCompanyMutation,
    useCreateIdpConfigMutation,
    useCreateSignupCodeMutation,
    useCreateSubscriptionMutation,
    useEnableKeycloakDirectPasswordMutation,
    useGetIdpIdByCompanyIdLazyQuery,
    useSetEmailAllowlistMutation
} from '../../../graphql/generated/autogenerated'
import { getDates } from '../../../utils'

const messages = defineMessages({
    companyCreationError: {
        defaultMessage: 'Failed to create company.',
        description: 'company creation error message'
    },
    challengeProvisionError: {
        defaultMessage: 'Failed to provision challenges.',
        description: 'challenge provision error message'
    },
    idpConfigCreationError: {
        defaultMessage: 'Failed to create idpConfig.',
        description: 'idp config creation error message'
    },
    subscriptionCreationError: {
        defaultMessage: 'Failed to create subscription.',
        description: 'subscription creation error message'
    },
    signupCodeCreationError: {
        defaultMessage: 'Failed to create sign-up code.',
        description: 'signupcode creation error message'
    },
    cmsCreationError: {
        defaultMessage: 'Failed to create company subscription in CMS.',
        description: 'cms creation error message'
    },
    emailAllowListCreationError: {
        defaultMessage: 'Failed to set email allow list.',
        description: 'email allow list creation error message'
    }
})

export enum CreationErrorType {
    Company,
    Subscription,
    SignupCode,
    IdpConfig,
    ChallengeProvision,
    Cms,
    EmailAllowList
}

interface CreationError {
    type: CreationErrorType
    message: string
    error?: Apollo.ApolloError
}

const CreationCallback: React.FC = () => {
    const intl = useIntl()
    const history = useHistory()
    const [loading, setLoading] = useState(true)
    const [errors, setErrors] = useState<CreationError[]>([])
    const [companyId, setCompanyId] = useState('')
    const dates = getDates()

    const createCompany = useAppSelector((state) => state.createCompany)
    const { name, brands, seatsAllowed, isPublic, status, imageUrl } =
        createCompany.company
    const { subscriptions } = createCompany
    const { signupCodes } = createCompany
    const { emailDomains } = createCompany

    const [createSubscriptionMutation] = useCreateSubscriptionMutation({
        onError: (error) => {
            setErrors([
                ...errors,
                {
                    type: CreationErrorType.Subscription,
                    message: `${intl.formatMessage(
                        messages.subscriptionCreationError
                    )}`,
                    error
                }
            ])
        },
        //Add associated sign-up codes for the created subscription
        onCompleted: (data) => {
            const dbSubscriptionId = data?.identity?.subscription?.create?.id
            const subscriptionName =
                data?.identity?.subscription?.create?.internalDescription
            //we mapped groupcodes to a temporary subscription id
            //so we need to get all the group codes for that temp id
            //and add them in the db with the real subscription id
            const tempSubscription = subscriptions.find(
                (subscription) =>
                    subscription.internalDescription === subscriptionName
            )
            signupCodes
                .filter(
                    (signupCode) =>
                        signupCode.subscriptionId === tempSubscription.id
                )
                .map((signupCode) => {
                    createSignupCodeMutation({
                        variables: {
                            input: {
                                subscriptionId: dbSubscriptionId,
                                code: signupCode.code,
                                validFrom: signupCode.validFrom,
                                validUntil: signupCode.validUntil
                            }
                        }
                    })
                })

            // provision challenges to the subscription
            addChallengesToSubscriptionMutation({
                variables: {
                    companyId,
                    subscriptionId: dbSubscriptionId
                }
            })

            //add to CMS
            // TODO: https://thrive-global.atlassian.net/browse/IDENTITY-1276
        }
    })

    const [createSignupCodeMutation] = useCreateSignupCodeMutation({
        onError: (error) => {
            setErrors([
                ...errors,
                {
                    type: CreationErrorType.SignupCode,
                    message: `${intl.formatMessage(
                        messages.signupCodeCreationError
                    )}`,
                    error
                }
            ])
        }
    })

    const [setEmailAllowlistMutation] = useSetEmailAllowlistMutation({
        onError: (error) => {
            setErrors([
                ...errors,
                {
                    type: CreationErrorType.EmailAllowList,
                    message: `${intl.formatMessage(
                        messages.emailAllowListCreationError
                    )}`,
                    error
                }
            ])
        }
    })

    const [getIdpId] = useGetIdpIdByCompanyIdLazyQuery({
        onCompleted: (data) => {
            if (emailDomains?.length > 0) {
                setEmailAllowlistMutation({
                    variables: {
                        idpConfigId:
                            data?.identity?.idpConfig?.tryGetByIdpCompanyId?.id,
                        input: {
                            emailAllowlist: emailDomains
                        }
                    }
                })
            }
        },
        onError: (error) =>
            setErrors([
                ...errors,
                {
                    type: CreationErrorType.EmailAllowList,
                    message: `${intl.formatMessage(
                        messages.emailAllowListCreationError
                    )}`,
                    error
                }
            ])
    })

    const [enableCredentialMutation] = useEnableKeycloakDirectPasswordMutation({
        onCompleted: () => {
            getIdpId({
                variables: {
                    idpCompanyId: companyId,
                    idpType: IdpType.KeycloakDirectPassword
                }
            })
        },
        onError: (error) => {
            setErrors([
                ...errors,
                {
                    type: CreationErrorType.IdpConfig,
                    message: `${intl.formatMessage(
                        messages.idpConfigCreationError
                    )}`,
                    error
                }
            ])
        }
    })

    const [createCompanyMutation] = useCreateCompanyMutation({
        variables: {
            input: {
                name,
                isPublic,
                brands,
                status,
                seatsAllowed: parseInt(seatsAllowed),
                ...(imageUrl && { imageUrl })
            }
        },
        onError: (error) => {
            setErrors([
                ...errors,
                {
                    type: CreationErrorType.Company,
                    message: `${intl.formatMessage(
                        messages.companyCreationError
                    )}`,
                    error
                }
            ])
        },
        //Create idpConfig and subscriptiions for the created company
        onCompleted: (data) => {
            const companyId = data?.identity?.company?.create?.id
            setCompanyId(companyId)

            enableCredentialMutation({
                variables: {
                    companyId
                }
            })

            subscriptions.map((subscription) => {
                createSubscriptionMutation({
                    variables: {
                        input: {
                            companyId,
                            // Todo: remove start/end when backend code is updated
                            startDate: dates.today,
                            expiryDate: dates.yearFromToday,
                            isDefault: subscription.isDefault,
                            enabled: subscription.enabled,
                            license: subscription.license,
                            subscriptionType: subscription.subscriptionType,
                            internalDescription:
                                subscription.internalDescription
                        }
                    }
                })
            })
        }
    })

    const [addChallengesToSubscriptionMutation] =
        useAddChallengesToSubscriptionMutation({
            onError: (error) => {
                setErrors([
                    ...errors,
                    {
                        type: CreationErrorType.ChallengeProvision,
                        message: intl.formatMessage(
                            messages.challengeProvisionError
                        ),
                        error
                    }
                ])
            }
        })

    //memoize the create company mutation so the reference is stable
    const memoizedCreateCompanyMutation = useCallback(() => {
        createCompanyMutation().then(() => {
            setLoading(false)
        })
    }, [createCompanyMutation])

    useEffect(() => {
        memoizedCreateCompanyMutation()
    }, [memoizedCreateCompanyMutation])

    const companyCreationFailed = () => {
        return errors.find((error) => error.type === CreationErrorType.Company)
    }

    return (
        <Grid item xs={12} data-testid="creation-callback">
            {loading && (
                <Box
                    sx={{
                        height: '100vh',
                        width: '100%',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <CircularProgress />
                </Box>
            )}
            <Stack spacing={2}>
                <CoreAlert severity="info">
                    <AlertTitle>
                        <CoreTypography variant="h5">
                            <FormattedMessage
                                description="Company creation info alert title"
                                defaultMessage="Note"
                            />
                        </CoreTypography>
                    </AlertTitle>
                    <CoreTypography variant="body1">
                        <FormattedMessage
                            description="Company creation info text"
                            defaultMessage="It may take up to 15 minutes for your company to become active."
                        />
                    </CoreTypography>
                </CoreAlert>
                {!loading && !companyCreationFailed() && (
                    <>
                        <CoreAlert
                            severity="success"
                            action={
                                <Box
                                    sx={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        height: '100%'
                                    }}
                                >
                                    <Button
                                        color="inherit"
                                        size="small"
                                        onClick={() => {
                                            history.push(
                                                `/company/${companyId}`
                                            )
                                        }}
                                    >
                                        <CoreTypography customVariant="buttonNormal">
                                            <FormattedMessage
                                                description="Label for button to view company"
                                                defaultMessage="View Company"
                                            />
                                        </CoreTypography>
                                    </Button>
                                </Box>
                            }
                        >
                            <AlertTitle>
                                <CoreTypography variant="h5">
                                    <FormattedMessage
                                        description="Company creation success alert title"
                                        defaultMessage="Success"
                                    />
                                </CoreTypography>
                            </AlertTitle>
                            <CoreTypography variant="body1">
                                <FormattedMessage
                                    description="Message shown if company creation was successful"
                                    defaultMessage="Company has been created."
                                />
                            </CoreTypography>
                        </CoreAlert>
                    </>
                )}
                {!loading && !!errors.length && (
                    <>
                        <CoreAlert severity="error">
                            <AlertTitle>
                                <CoreTypography variant="h5">
                                    <FormattedMessage
                                        description="Company creation error alert title"
                                        defaultMessage="Error"
                                    />
                                </CoreTypography>
                            </AlertTitle>
                            {errors.map((error, index) => {
                                return (
                                    <Box key={index}>
                                        <CoreTypography variant="body1">
                                            {error.message}
                                            {error.error?.message}
                                        </CoreTypography>
                                    </Box>
                                )
                            })}
                        </CoreAlert>
                    </>
                )}
            </Stack>
        </Grid>
    )
}

export default CreationCallback
