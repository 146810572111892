import {
    Box,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions
} from '@mui/material'
import { v4 as uuidv4 } from 'uuid'
import React from 'react'
import { ModalProps } from '../../../../../components/buttons'
import { useAppDispatch } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { addSubscription } from '../../../../../slices/createCompany'
import { FormattedMessage } from 'react-intl'
import {
    LicenseType,
    SubscriptionType,
    UserSubscription
} from '../../../../../graphql/generated/autogenerated'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import {
    SubscriptionInput,
    SubscriptionFormInputs
} from '../../../../../components/inputs/SubscriptionInput/SubscriptionInput'

export const AddSubscriptionModal: React.FC<ModalProps> = ({
    closeModal,
    trigger
}) => {
    const dispatch = useAppDispatch()
    const allSubscriptions = trigger.subscriptions

    const defaultValues = {
        name: '',
        isDefault: false,
        enabled: Number(true),
        subscriptionType: SubscriptionType.Employee,
        license: LicenseType.Standard
    }

    const formMethods = useForm<SubscriptionFormInputs>({
        defaultValues: defaultValues,
        reValidateMode: 'onSubmit'
    })

    const getAlreadyExists = (value) => {
        return !!allSubscriptions.find(
            (sub) => sub.internalDescription === value
        )
    }

    const handleAddSubscriptionClick: SubmitHandler<
        SubscriptionFormInputs
    > = async (data) => {
        const newSubscription = {
            //create tempId to link groupcodes to
            id: uuidv4(),
            internalDescription: data.name.trim(),
            isDefault: data.isDefault,
            enabled: Boolean(data.enabled),
            license: data.license,
            subscriptionType: data.subscriptionType
        } as Partial<UserSubscription>
        dispatch(addSubscription(newSubscription))
        closeModal()
    }

    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>
                <FormattedMessage
                    defaultMessage="Create new subscription"
                    description="Title for create new subscription modal"
                />
            </DialogTitle>
            <DialogContent>
                <FormProvider {...formMethods}>
                    <Box component="form" id="add-subscription-form" noValidate>
                        <SubscriptionInput
                            canSetDefault={true}
                            getAlreadyExists={getAlreadyExists}
                        />
                    </Box>
                </FormProvider>
            </DialogContent>
            <DialogActions>
                <Button
                    form="add-subscription-form"
                    variant="contained"
                    color="primary"
                    onClick={formMethods.handleSubmit(
                        handleAddSubscriptionClick
                    )}
                    sx={{ ml: 2 }}
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            description="Label for button to save subscription"
                            defaultMessage="Add"
                        />
                    </CoreTypography>
                </Button>
            </DialogActions>
        </>
    )
}
