import React, { ReactNode } from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import { TextField } from '@mui/material'

const messages = defineMessages({
    workspaceIdHelperText: {
        defaultMessage: 'Enter workspace ID to enable Slack',
        description: 'Helper text for workspace ID input'
    },
    workspaceIdInputLabel: {
        defaultMessage: 'Workspace ID',
        description: 'Label for workspace id input'
    },
    workspaceIdRequiredError: {
        defaultMessage: 'Workspace ID is required.',
        description: 'Error text for required workspace id input'
    }
})

export interface WorkspaceIdFormInputs {
    workspaceId: string
}

export const WorkspaceIdInput: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <>
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        spellCheck="false"
                        id="idp-id"
                        variant="outlined"
                        inputRef={field.ref}
                        value={field.value}
                        error={!!errors?.workspaceId}
                        onChange={field.onChange}
                        label={intl.formatMessage(
                            messages.workspaceIdInputLabel
                        )}
                        helperText={
                            <>
                                {errors?.workspaceId
                                    ? errors?.workspaceId?.message
                                    : intl.formatMessage(
                                          messages.workspaceIdHelperText
                                      )}
                            </>
                        }
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                control={control}
                rules={{
                    required: intl.formatMessage(
                        messages.workspaceIdRequiredError
                    ),
                    validate: {
                        notJustWhiteSpace: (value) => {
                            if (!value.trim())
                                return intl.formatMessage(
                                    messages.workspaceIdRequiredError
                                )
                        }
                    }
                }}
                name="workspaceId"
            />
        </>
    )
}
