import {
    centerAspectCrop,
    updateTransformedImageCanvas
} from '../../../utils/logo'
import 'react-image-crop/dist/ReactCrop.css'
import { Box, Input, Button } from '@mui/material'
import ReactCrop, { Crop, PixelCrop } from 'react-image-crop'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import React, { MutableRefObject, useRef, useState } from 'react'
import { useDebounceEffect } from '../../../hooks/useDebounceEffect'
import { defineMessages, useIntl, FormattedMessage } from 'react-intl'

interface LogoUploadProps {
    scale: number
    imgSrc: string
    type: 'company' | 'brand'
    setScale: (num: number) => void
    setImgSrc: (src: string) => void
    transformedImageCanvasRef: MutableRefObject<HTMLCanvasElement>
    uploadButtonRef: MutableRefObject<any>
}

const messages = defineMessages({
    imageLabel: {
        defaultMessage: 'Uploaded Image',
        description: 'Label for uploaded image'
    }
})

export const LogoUpload: React.FC<LogoUploadProps> = ({
    scale,
    imgSrc,
    type,
    setScale,
    setImgSrc,
    transformedImageCanvasRef,
    uploadButtonRef
}) => {
    const intl = useIntl()

    const aspect = 1
    const imgRef = useRef<HTMLImageElement>(null)
    const [crop, setCrop] = useState<Crop>()
    const [completedCrop, setCompletedCrop] = useState<PixelCrop>()

    const reader = new FileReader()
    reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
    })

    const handleImageSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
        if (e?.target?.files && e?.target?.files[0]) {
            //reset scale if user selects a new image
            setScale(1)
            setCrop(undefined)
            reader.readAsDataURL(e.target.files[0])
        }
    }

    function onImageLoad(e: React.SyntheticEvent<HTMLImageElement>) {
        const { width, height } = e.currentTarget
        setCrop(centerAspectCrop(width, height, aspect))
    }

    useDebounceEffect(
        async () => {
            if (
                completedCrop?.width &&
                completedCrop?.height &&
                imgRef.current &&
                transformedImageCanvasRef.current
            ) {
                updateTransformedImageCanvas(
                    imgRef.current,
                    transformedImageCanvasRef.current,
                    completedCrop,
                    scale
                )
            }
        },
        100,
        [completedCrop, scale]
    )

    return (
        <>
            <Box>
                <Box
                    data-testid="form-file-upload"
                    sx={{
                        height: '300px',
                        display: imgSrc ? 'none' : 'flex',
                        justifyContent: 'center',
                        alignItems: 'center'
                    }}
                >
                    <label htmlFor={`edit-${type}-logo-img`}>
                        <Button
                            variant="outlined"
                            startIcon={
                                <LeafIcon
                                    icon={'arrow-up-from-bracket'}
                                    fontSize={'small'}
                                />
                            }
                            component="label"
                            ref={uploadButtonRef}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Text for photo upload button"
                                    defaultMessage="Upload photo"
                                />
                            </CoreTypography>
                            <Input
                                id={`edit-${type}-logo-img`}
                                type="file"
                                onChange={handleImageSelect}
                                inputProps={{
                                    accept: 'image/*',
                                    'data-testid': 'file-upload-input'
                                }}
                                sx={{ display: 'none' }}
                            />
                        </Button>
                    </label>
                </Box>
                {!!imgSrc && (
                    <Box
                        sx={{
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center'
                        }}
                    >
                        <ReactCrop
                            crop={crop}
                            onChange={(_, percentCrop) => setCrop(percentCrop)}
                            onComplete={(c) => setCompletedCrop(c)}
                            aspect={aspect}
                        >
                            <img
                                ref={imgRef}
                                alt={intl.formatMessage(messages.imageLabel)}
                                data-testid="uploaded-image"
                                src={imgSrc}
                                style={{
                                    width: 'auto',
                                    maxHeight: '300px',
                                    transform: `scale(${scale})`
                                }}
                                onLoad={onImageLoad}
                            />
                        </ReactCrop>
                        {/* reference to transformed version of image */}
                        <canvas
                            data-testid="transformed-image-canvas"
                            ref={transformedImageCanvasRef}
                            style={{
                                display: 'none'
                            }}
                        />
                    </Box>
                )}
            </Box>
        </>
    )
}
