import {
    Grid,
    InputAdornment,
    InputLabel,
    TextField,
    FormControl,
    MenuItem,
    Select,
    SelectChangeEvent,
    Stack
} from '@mui/material'
import { CompanyWithIdpInfo } from '../../../types'
import React, { useEffect, useState } from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'

const messages = defineMessages({
    searchByLabel: {
        defaultMessage: 'Search By',
        description: 'Label for company search by field'
    },
    searchLabel: {
        defaultMessage: 'Search',
        description: 'Label for company search field'
    },
    nameSearchLabel: {
        defaultMessage: 'Name',
        description: 'Label for company name search'
    },
    nameSearchAriaLabel: {
        defaultMessage: 'Search by company name',
        description: 'Aria label for company name search'
    },
    companyIdSearchLabel: {
        defaultMessage: 'Company ID',
        description: 'Label for company id search'
    },
    companyIdSearchAriaLabel: {
        defaultMessage: 'Search by Company ID',
        description: 'Aria label for company id search'
    },
    signinMethodSearchLabel: {
        defaultMessage: 'Platform/Sign-in Method',
        description: 'Label for platform/signin method search'
    },
    signinMethodSearchAriaLabel: {
        defaultMessage: 'Search by platform or sign-in method',
        description: 'Aria label for platform/signin method search'
    },
    tenantIdSearchLabel: {
        defaultMessage: 'Teams Tenant ID',
        description: 'Label for teams tenant id search'
    },
    tenantIdSearchAriaLabel: {
        defaultMessage: 'Search by teams tenant id',
        description: 'Aria label for teams tenant id search'
    },
    workspaceIdSearchLabel: {
        defaultMessage: 'Slack Workspace ID',
        description: 'Label for slack workspace id search'
    },
    workspaceIdSearchAriaLabel: {
        defaultMessage: 'Search by Slack workspace id',
        description: 'Aria label for slack workspace id search'
    }
})

interface DebouncedCompanySearchProps {
    search: string
    searchBy: keyof CompanyWithIdpInfo
    setSearchBy: (searchBy: keyof CompanyWithIdpInfo) => void
    setSearch: (search: string) => void
    setLoading: (value: boolean) => void
}

export const DebouncedCompanySearch: React.FC<DebouncedCompanySearchProps> = ({
    search,
    searchBy,
    setSearch,
    setSearchBy,
    setLoading
}) => {
    const intl = useIntl()

    //Debounced Search
    const [debouncedSearch, setDebouncedSearch] = useState(search)

    useEffect(() => {
        const timer = setTimeout(() => {
            setSearch(debouncedSearch)
            setLoading(false)
        }, 500)
        return () => clearTimeout(timer)
    }, [debouncedSearch, setSearch, setLoading])

    const handleSearchChange = (event) => {
        setLoading(true)
        setDebouncedSearch(event.target.value)
    }

    //Debounced search by
    const [debouncedSearchBy, setDebouncedSearchBy] =
        useState<keyof CompanyWithIdpInfo>(searchBy)

    useEffect(() => {
        const timer = setTimeout(() => {
            setSearchBy(debouncedSearchBy)
            setLoading(false)
        }, 100)
        return () => clearTimeout(timer)
    }, [debouncedSearchBy, setSearchBy, setLoading])

    const handleSearchByChange = (event: SelectChangeEvent) => {
        const newSearchBy = event.target.value as keyof CompanyWithIdpInfo
        setDebouncedSearchBy(newSearchBy)
        if (search !== '') {
            setLoading(true)
            setSearch('')
            setDebouncedSearch('')
        }
    }

    return (
        <Grid item xs={12} md={9}>
            <Stack direction="row" spacing={2}>
                <FormControl variant="outlined" fullWidth>
                    <InputLabel id="search-select-label">
                        {intl.formatMessage(messages.searchByLabel)}
                    </InputLabel>
                    <Select
                        fullWidth
                        labelId="search-select-label"
                        id="search-select"
                        value={debouncedSearchBy}
                        label={intl.formatMessage(messages.searchByLabel)}
                        onChange={handleSearchByChange}
                        size="small"
                        sx={{
                            '.MuiOutlinedInput-input': {
                                display: 'flex',
                                alignItems: 'center'
                            }
                        }}
                    >
                        {/* use TS to enforce that these are valid keys */}
                        <MenuItem
                            value="name"
                            aria-label={intl.formatMessage(
                                messages.nameSearchAriaLabel
                            )}
                        >
                            {intl.formatMessage(messages.nameSearchLabel)}
                        </MenuItem>
                        <MenuItem
                            value="id"
                            aria-label={intl.formatMessage(
                                messages.companyIdSearchAriaLabel
                            )}
                        >
                            {intl.formatMessage(messages.companyIdSearchLabel)}
                        </MenuItem>
                        <MenuItem
                            value="idpTypeSearchString"
                            aria-label={intl.formatMessage(
                                messages.signinMethodSearchLabel
                            )}
                        >
                            {intl.formatMessage(
                                messages.signinMethodSearchLabel
                            )}
                        </MenuItem>
                        <MenuItem
                            value="tenantIdSearchString"
                            aria-label={intl.formatMessage(
                                messages.tenantIdSearchAriaLabel
                            )}
                        >
                            {intl.formatMessage(messages.tenantIdSearchLabel)}
                        </MenuItem>
                        <MenuItem
                            value="workspaceIdSearchString"
                            aria-label={intl.formatMessage(
                                messages.workspaceIdSearchAriaLabel
                            )}
                        >
                            {intl.formatMessage(
                                messages.workspaceIdSearchLabel
                            )}
                        </MenuItem>
                    </Select>
                </FormControl>
                <TextField
                    fullWidth
                    id="search-filter"
                    variant="outlined"
                    label={intl.formatMessage(messages.searchLabel)}
                    size="small"
                    value={debouncedSearch}
                    onChange={handleSearchChange}
                    InputProps={{
                        startAdornment: (
                            <InputAdornment position="start">
                                <LeafIcon
                                    icon={'magnifying-glass'}
                                    fontSize={'small'}
                                />
                            </InputAdornment>
                        )
                    }}
                />
            </Stack>
        </Grid>
    )
}
