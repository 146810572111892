import React from 'react'
import { FormattedMessage } from 'react-intl'
import { SubscriptionListItem } from '../../list-items'
import { UserSubscription } from '../../../graphql/generated/autogenerated'
import {
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    TableContainer
} from '@mui/material'
import { ModalProps } from '../../buttons'

export interface SubscriptionListProps {
    subscriptions: Partial<UserSubscription>[]
    editModalComponent: React.FC<ModalProps>
    showId?: boolean
}

export const SubscriptionList: React.FC<SubscriptionListProps> = ({
    subscriptions,
    editModalComponent,
    showId = true
}) => {
    return (
        <TableContainer>
            <Table
                data-testid="subscription-list"
                aria-labelledby="subscription-table-header"
            >
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage
                                description="Label for subscription name"
                                defaultMessage="Name"
                            />
                        </TableCell>
                        {showId && (
                            <TableCell>
                                <FormattedMessage
                                    description="Label for subscription id"
                                    defaultMessage="ID"
                                />
                            </TableCell>
                        )}
                        <TableCell>
                            <FormattedMessage
                                description="Label for subscription type"
                                defaultMessage="Type"
                            />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage
                                description="Label for subscription license type"
                                defaultMessage="License"
                            />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage
                                description="Label for defaut subscription"
                                defaultMessage="Default"
                            />
                        </TableCell>
                        <TableCell>
                            <FormattedMessage
                                description="Label for subscription enabled status"
                                defaultMessage="Status"
                            />
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {subscriptions.map((subscription) => (
                        <SubscriptionListItem
                            editModalComponent={editModalComponent}
                            subscriptions={subscriptions}
                            item={subscription}
                            key={subscription.id}
                            showId={showId}
                        />
                    ))}
                </TableBody>
            </Table>
        </TableContainer>
    )
}
