import React from 'react'
import { ROUTES } from '../../../routes'
import { useHistory } from 'react-router-dom'
import { Box, Button, Divider, Stack } from '@mui/material'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { useAppDispatch } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { resetState } from '../../../slices/createCompany'

const messages = defineMessages({
    cancelLabel: {
        defaultMessage: 'cancel create new company',
        description: 'Label for cancel button'
    },
    backLabel: {
        defaultMessage: 'go back',
        description: 'Label for back button'
    },
    nextLabel: {
        defaultMessage: 'go to next page',
        description: 'Label for next button'
    }
})

export interface TabBarNavProps {
    handleNextClick: any
    handleBackClick?: () => void
    isFinalTab?: boolean
    isFirstTab?: boolean
}

export const TabBarNav: React.FC<TabBarNavProps> = ({
    handleNextClick,
    handleBackClick,
    isFinalTab = false,
    isFirstTab = false
}) => {
    const intl = useIntl()
    const history = useHistory()
    const dispatch = useAppDispatch()

    const handleCancelClick = () => {
        history.push(ROUTES.HOME)
        dispatch(resetState())
    }

    return (
        <>
            <Box sx={{ mb: 2 }}>
                <Divider />
            </Box>
            <Box
                data-testid="tab-bar-bottom-nav"
                sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Button
                    variant="text"
                    size="large"
                    color="primary"
                    onClick={handleCancelClick}
                    aria-label={intl.formatMessage(messages.cancelLabel)}
                >
                    <CoreTypography customVariant="buttonNormal">
                        <FormattedMessage
                            description="Label for cancel button"
                            defaultMessage="Cancel"
                        />
                    </CoreTypography>
                </Button>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ alignItems: 'center' }}
                >
                    {!isFirstTab && (
                        <Button
                            variant="text"
                            size="large"
                            color="primary"
                            onClick={handleBackClick}
                            aria-label={intl.formatMessage(messages.backLabel)}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for button to go back"
                                    defaultMessage="Back"
                                />
                            </CoreTypography>
                        </Button>
                    )}
                    {isFinalTab ? (
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleNextClick}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for create company button"
                                    defaultMessage="Create company"
                                />
                            </CoreTypography>
                        </Button>
                    ) : (
                        <Button
                            variant="contained"
                            size="large"
                            color="primary"
                            onClick={handleNextClick}
                            aria-label={intl.formatMessage(messages.nextLabel)}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for button go to next page"
                                    defaultMessage="Next"
                                />
                            </CoreTypography>
                        </Button>
                    )}
                </Stack>
            </Box>
        </>
    )
}
