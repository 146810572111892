import { InputLabel, FormControl, MenuItem, Select } from '@mui/material'
import { Company } from '../../../graphql/generated/autogenerated'
import React, { useEffect, useState } from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Order } from '../../../utils/sort'

const messages = defineMessages({
    sortLabel: {
        defaultMessage: 'Sort',
        description: 'Label for company sort selector'
    },
    alphabeticalLabel: {
        defaultMessage: 'Name: Asc',
        description: 'Label for company sort alphabetical option'
    },
    reverseAlphabeticalLabel: {
        defaultMessage: 'Name: Desc',
        description: 'Label for company sort reverse-alphabetical option'
    },
    dateCreatedAscLabel: {
        defaultMessage: 'Date: Asc',
        description: 'Label for company sort by created date ascending option'
    },
    dateCreatedDescLabel: {
        defaultMessage: 'Date: Desc',
        description: 'Label for company sort by created date descending option'
    }
})

interface DebouncedCompanySortProps {
    order: Order
    orderBy: keyof Company
    setOrderBy: (orderBy: keyof Company) => void
    setOrder: (order: Order) => void
    setLoading: (value: boolean) => void
}

export const DebouncedCompanySort: React.FC<DebouncedCompanySortProps> = ({
    order,
    orderBy,
    setOrderBy,
    setOrder,
    setLoading
}) => {
    const intl = useIntl()

    const [debouncedOrder, setDebouncedOrder] = useState<Order>(order)
    const [debouncedOrderBy, setDebouncedOrderBy] =
        useState<keyof Company>(orderBy)

    useEffect(() => {
        setLoading(true)
        const timer = setTimeout(() => {
            setOrderBy(debouncedOrderBy)
            setOrder(debouncedOrder)
            setLoading(false)
        }, 100)
        return () => clearTimeout(timer)
    }, [debouncedOrder, debouncedOrderBy, setOrderBy, setOrder, setLoading])

    const handleSortChange = (e) => {
        const sort = (e.target.value as string).split('-')
        setDebouncedOrderBy(sort[0] as keyof Company)
        setDebouncedOrder(sort[1] as Order)
    }

    return (
        <FormControl variant="outlined">
            <InputLabel id="sort-select-label">
                {intl.formatMessage(messages.sortLabel)}
            </InputLabel>
            <Select
                labelId="sort-select-label"
                id="sort-select"
                value={`${debouncedOrderBy}-${debouncedOrder}`}
                label={intl.formatMessage(messages.sortLabel)}
                onChange={handleSortChange}
                size="small"
                sx={{
                    '.MuiOutlinedInput-input': {
                        display: 'flex',
                        alignItems: 'center'
                    }
                }}
            >
                <MenuItem value="name-asc">
                    {intl.formatMessage(messages.alphabeticalLabel)}
                </MenuItem>
                <MenuItem value="name-desc">
                    {intl.formatMessage(messages.reverseAlphabeticalLabel)}
                </MenuItem>
                <MenuItem value="createdAt-asc">
                    {intl.formatMessage(messages.dateCreatedAscLabel)}
                </MenuItem>
                <MenuItem value="createdAt-desc">
                    {intl.formatMessage(messages.dateCreatedDescLabel)}
                </MenuItem>
            </Select>
        </FormControl>
    )
}
