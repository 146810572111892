// supports UUID v1-5
export const validateUuid = (uuid: string): boolean => {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
        uuid
    )
}

// supports UUID v1-8
export const validateUuidExtended = (uuid: string): boolean => {
    return /^[0-9a-f]{8}-[0-9a-f]{4}-[1-8][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(
        uuid
    )
}

// accepts all UUID-shaped strings (XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX)
export const validateUuidShape = (uuid: string): boolean => {
    return /^[\w]{8}-[\w]{4}-[\w]{4}-[\w]{4}-[\w]{12}$/i.test(uuid)
}
