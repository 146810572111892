//YYYY-MM-DDT00:00:00.000Z
export const getDates = () => {
    const now = new Date()
    now.setUTCHours(0, 0, 0, 0)
    const today = now.toISOString()

    const yearFromNow = new Date(
        new Date(today).setFullYear(new Date().getFullYear() + 1)
    )
    const yearFromToday = yearFromNow.toISOString()

    return {
        today,
        yearFromToday
    }
}
