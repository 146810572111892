import React from 'react'
import { Grid } from '@mui/material'
import Brands from './Brands/Brands'
import {
    setCompanyName,
    setSeatsAllowed,
    setCompanyStatus,
    setIsPublic,
    setActiveTab,
    editSubscription
} from '../../../slices/createCompany'
import CompanyName from './CompanyName/CompanyName'
import { CreateCompanyTabs } from '../CreateCompany'
import { TabBarNav } from '../../../components/tabs'
import { CompanyFormInputs } from '../../../components/inputs'
import { useForm, FormProvider, SubmitHandler } from 'react-hook-form'
import { useAppSelector, useAppDispatch } from '@thriveglobal/thrive-web-core'

const CompanyAndBrands: React.FC = () => {
    const dispatch = useAppDispatch()

    const createCompany = useAppSelector((state) => state.createCompany)
    const { name, seatsAllowed, isPublic, status } = createCompany?.company

    const defaultValues = {
        name,
        seatsAllowed: seatsAllowed.toString(),
        isPublic: !isPublic,
        status
    }

    const formMethods = useForm<CompanyFormInputs>({
        defaultValues: defaultValues,
        reValidateMode: 'onSubmit'
    })

    const handleNextClick: SubmitHandler<CompanyFormInputs> = async (data) => {
        dispatch(setCompanyName(data.name.trim()))
        dispatch(setSeatsAllowed(data.seatsAllowed.trim()))
        dispatch(setCompanyStatus(data.status))
        // Reverse checkbox value since hide = checkbox: true, isPublic:false
        // and show = checkbox:false, isPublic:true
        dispatch(setIsPublic(!data.isPublic))
        dispatch(setActiveTab(CreateCompanyTabs.SubscriptionsAndCodes))
        dispatch(
            editSubscription({
                id: '44eb4c9a-37ed-4e55-a1c8-5201c8c0c9c7',
                internalDescription: `${data.name.trim()} Main`
            })
        )
        dispatch(
            editSubscription({
                id: '026f3e19-d4cb-40b5-99cf-22270786cd4f',
                internalDescription: `${data.name.trim()} F&F`
            })
        )
    }

    return (
        <>
            <Grid
                item
                container
                component="form"
                id="company-details-form"
                noValidate
            >
                <FormProvider {...formMethods}>
                    <CompanyName />
                </FormProvider>
            </Grid>
            <Brands />
            <Grid item xs={12}>
                <TabBarNav
                    handleNextClick={formMethods.handleSubmit(handleNextClick)}
                    isFirstTab={true}
                    isFinalTab={false}
                />
            </Grid>
        </>
    )
}

export default CompanyAndBrands
