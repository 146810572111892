import React from 'react'
import { Box, Button } from '@mui/material'
import { useHistory } from 'react-router-dom'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'

export interface BackButtonProps {
    link?: string
    label: string
}

export const BackButton: React.FC<BackButtonProps> = ({ link, label }) => {
    const history = useHistory()
    return (
        <Box sx={{ mt: 4 }}>
            <Button
                variant="text"
                startIcon={<LeafIcon icon={'arrow-left'} fontSize={'small'} />}
                size="large"
                color="primary"
                onClick={() => {
                    link ? history.push(link) : history.goBack()
                }}
            >
                <CoreTypography customVariant="buttonNormal">
                    {label}
                </CoreTypography>
            </Button>
        </Box>
    )
}
