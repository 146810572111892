import { CompanyWithIdpInfo } from '../types'

export function descendingComparator<CompanyWithIdpInfo>(
    a: CompanyWithIdpInfo,
    b: CompanyWithIdpInfo,
    orderBy: keyof CompanyWithIdpInfo
) {
    if (orderBy === 'createdAt') {
        const aDate = new Date(a[orderBy] as unknown as string)
        const bDate = new Date(b[orderBy] as unknown as string)
        if (bDate < aDate) {
            return -1
        }
        if (bDate > aDate) {
            return 1
        }
        return 0
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1
        }
        if (b[orderBy] > a[orderBy]) {
            return 1
        }
        return 0
    }
}

export type Order = 'asc' | 'desc'

export function getComparator<Key extends keyof CompanyWithIdpInfo>(
    order: Order,
    orderBy: Key
): (a: CompanyWithIdpInfo, b: CompanyWithIdpInfo) => number {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy)
}
