import {
    Box,
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack
} from '@mui/material'
import React, { useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { ModalProps } from '../../../../components/buttons'
import { useParams } from 'react-router-dom'
import { useAddBrandMutation } from '../../../../graphql/generated/autogenerated'
import BrandInput, {
    BrandFormInputs
} from '../../../../components/inputs/BrandInput/BrandInput'
import { GET_COMPANY_DATA } from '../../../../graphql/queries/companies'
import { LogoInput } from '../../../../components/inputs'

export const AddBrandModal: React.FC<ModalProps> = ({ closeModal }) => {
    const { id } = useParams()
    const [logoUrl, setLogoUrl] = useState('')

    const defaultValues = {
        name: '',
        isPublic: true
    }

    const formMethods = useForm<BrandFormInputs>({
        defaultValues: defaultValues,
        reValidateMode: 'onSubmit'
    })

    const [addBrandMutation, { data, loading, error }] = useAddBrandMutation({
        onCompleted: () => {
            closeModal && closeModal()
        },
        refetchQueries: [
            {
                query: GET_COMPANY_DATA,
                variables: { companyId: id }
            },
            'GetCompanyData'
        ]
    })

    const handleAddBrandClick: SubmitHandler<BrandFormInputs> = async (
        data
    ) => {
        addBrandMutation({
            variables: {
                input: {
                    companyId: id,
                    name: data.name,
                    imageUrl: logoUrl,
                    isPublic: !data.isPublic
                }
            }
        })
    }

    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>
                <FormattedMessage
                    defaultMessage="Add brand"
                    description="Title for add new brand modal"
                />
            </DialogTitle>
            <DialogContent>
                {error ? (
                    <CoreTypography variant="body2" sx={{ color: 'red' }}>
                        <FormattedMessage
                            description="Add brand error text"
                            defaultMessage="There was an error with your request."
                        />
                        {error?.message}
                    </CoreTypography>
                ) : (
                    <Stack
                        spacing={2}
                        component="form"
                        id="add-brand-form"
                        noValidate
                    >
                        <FormProvider {...formMethods}>
                            <LogoInput
                                type="brand"
                                logoUrl={logoUrl}
                                setLogoUrl={setLogoUrl}
                            />
                            <BrandInput fullsize />
                        </FormProvider>
                    </Stack>
                )}
            </DialogContent>
            {!error && (
                <DialogActions>
                    <Box sx={{ p: 2 }}>
                        <Button
                            form="add-brand-form"
                            variant="contained"
                            color="primary"
                            onClick={formMethods.handleSubmit(
                                handleAddBrandClick
                            )}
                            sx={{ ml: 2 }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for button to add brand"
                                    defaultMessage="Add"
                                />
                            </CoreTypography>
                        </Button>
                    </Box>
                </DialogActions>
            )}
        </>
    )
}
