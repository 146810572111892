import { gql } from '@thriveglobal/thrive-web-core'

export const GET_ALL_COMPANIES_AND_CONFIGS = gql`
    query getAllCompaniesAndConfigs {
        identity {
            company {
                getAll(limit: 10000, publicOnly: false) {
                    items {
                        brands {
                            companyId
                            createdAt
                            imageUrl
                            isPublic
                            name
                            updatedAt
                        }
                        configuration
                        createdAt
                        id
                        imageUrl
                        isPublic
                        name
                        seatsAllowed
                        status
                        updatedAt
                    }
                }
            }
            idpConfig {
                getAll(limit: 10000) {
                    items {
                        companyId
                        configuration
                        emailAllowList
                        enabled
                        id
                        idpCompanyId
                        idpType
                        loginMethod
                    }
                }
            }
        }
    }
`

export const GET_ALL_COMPANIES = gql`
    query getAllCompanies {
        identity {
            company {
                getAll(limit: 10000, publicOnly: false) {
                    items {
                        brands {
                            imageUrl
                            isPublic
                            name
                            companyId
                            createdAt
                            updatedAt
                        }
                        configuration
                        id
                        imageUrl
                        isPublic
                        name
                        seatsAllowed
                        status
                        createdAt
                        updatedAt
                    }
                }
            }
        }
    }
`

export const GET_COMPANY_DATA = gql`
    query getCompanyData($companyId: UUID!) {
        identity {
            company {
                tryGet(id: $companyId) {
                    brands {
                        imageUrl
                        isPublic
                        name
                        companyId
                        createdAt
                        updatedAt
                    }
                    configuration
                    id
                    imageUrl
                    isPublic
                    name
                    seatsAllowed
                    status
                    createdAt
                    updatedAt
                }
            }
        }
    }
`

export const GET_COMPANY_SUBSCRIPTIONS = gql`
    query getCompanySubscriptions(
        $subscriptionFilters: SubscriptionFiltersInput
    ) {
        identity {
            subscription {
                getAll(filters: $subscriptionFilters) {
                    items {
                        companyId
                        expiryDate
                        id
                        internalDescription
                        isDefault
                        startDate
                        enabled
                        license
                        subscriptionType
                    }
                    total
                }
            }
        }
    }
`

export const GET_SUBSCRIPTION_SIGNUP_CODES = gql`
    query getSubscriptionSignupCodes(
        $signupCodeFilters: SignupCodeFiltersInput
        $page: Int
        $limit: Int
    ) {
        identity {
            signupCode {
                getAll(
                    filters: $signupCodeFilters
                    page: $page
                    limit: $limit
                ) {
                    items {
                        code
                        subscriptionId
                        validFrom
                        validUntil
                    }
                    itemsPerPage
                    page
                    total
                }
            }
        }
    }
`
export const GET_OWNED_EMAIL_DOMAINS = gql`
    query GetOwnedEmailDomains($companyId: UUID!) {
        identity {
            company {
                getOwnedEmailDomains(companyId: $companyId) {
                    emailDomain
                }
            }
        }
    }
`

export const GET_SIGNUP_CODE = gql`
    query getSignupCode($signupCode: String!) {
        identity {
            signupCode {
                tryGet(signupCode: $signupCode) {
                    code
                }
            }
        }
    }
`
