import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box
} from '@mui/material'
import React from 'react'
import {
    editSignupCode,
    removeSignupCode
} from '../../../../../slices/createCompany'
import { FormattedMessage } from 'react-intl'
import { ModalProps } from '../../../../../components/buttons'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import {
    SignupCodeFormInputs,
    SignupCodeInput
} from '../../../../../components/inputs/SignupCodeInput/SignupCodeInput'
import { useAppDispatch, useAppSelector } from '@thriveglobal/thrive-web-core'

export const EditSignupCodeModal: React.FC<ModalProps> = ({
    closeModal,
    trigger
}) => {
    const dispatch = useAppDispatch()
    const createCompany = useAppSelector((state) => state.createCompany)
    const signupCodes = createCompany?.signupCodes
    const {
        code: originalCode,
        validFrom,
        validUntil,
        subscriptionId
    } = trigger

    const defaultValues = {
        code: originalCode,
        startDate: validFrom,
        endDate: validUntil
    }

    const formMethods = useForm<SignupCodeFormInputs>({
        defaultValues: defaultValues,
        reValidateMode: 'onSubmit'
    })

    const getAlreadyExists = (value) => {
        return (
            value !== defaultValues.code &&
            !!signupCodes.find((el) => el.code === value)
        )
    }

    const handleDeleteSignupCodeClick = () => {
        dispatch(removeSignupCode(originalCode))
        closeModal()
    }

    const handleEditSignupCodeClick: SubmitHandler<
        SignupCodeFormInputs
    > = async (data) => {
        const updatedCode = {
            subscriptionId: subscriptionId,
            originalCode,
            code: data.code,
            validFrom: data.startDate,
            validUntil: data.endDate
        }
        dispatch(editSignupCode(updatedCode))
        closeModal()
    }

    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>
                <FormattedMessage
                    defaultMessage="Edit sign-up code"
                    description="Title for add edit signup code modal"
                />
            </DialogTitle>
            <DialogContent>
                <Box component="form" id="edit-signup-code-form" noValidate>
                    <FormProvider {...formMethods}>
                        <SignupCodeInput getAlreadyExists={getAlreadyExists} />
                    </FormProvider>
                </Box>
            </DialogContent>
            <DialogActions>
                <Box sx={{ p: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleDeleteSignupCodeClick}
                        sx={{ mr: 2 }}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to delete signup code"
                                defaultMessage="Delete"
                            />
                        </CoreTypography>
                    </Button>
                    <Button
                        form="edit-signup-code-form"
                        variant="contained"
                        color="primary"
                        onClick={formMethods.handleSubmit(
                            handleEditSignupCodeClick
                        )}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to save subscription"
                                defaultMessage="Save"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            </DialogActions>
        </>
    )
}
