import React from 'react'
import { ModalButton } from '../../buttons'
import { Stack, Chip, Avatar } from '@mui/material'
import { ModalButtonVariant } from '../../../types'
import { Brand } from '../../../graphql/generated/autogenerated'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'
import { EditBrandModal } from '../../../pages/Company/CompanyHeader/EditBrandModal/EditBrandModal'
import { AddBrandModal } from '../../../pages/Company/CompanyHeader/AddBrandModal/AddBrandModal'

export interface BrandBarProps {
    brands: Brand[]
}

const messages = defineMessages({
    editBrandLabel: {
        defaultMessage: 'Edit brand',
        description: 'Label for edit brand button'
    },
    addBrandLabel: {
        defaultMessage: 'Add brand',
        description: 'Label for add brand button'
    }
})

interface EditBrandButtonProps {
    brand: Partial<Brand>
}
const EditBrandButton: React.FC<EditBrandButtonProps> = ({ brand }) => {
    const intl = useIntl()
    return (
        <ModalButton
            buttonLabel={intl.formatMessage(messages.editBrandLabel)}
            modalButtonVariant={ModalButtonVariant.Icon}
            iconName={'pen'}
            ModalComponent={EditBrandModal}
            trigger={{ brand }}
            size="medium"
        />
    )
}

export const BrandBar: React.FC<BrandBarProps> = ({ brands }) => {
    const intl = useIntl()
    const theme = useTheme()
    return (
        <Stack
            direction="row"
            spacing={!brands?.length ? 0 : 1}
            sx={{
                display: 'flex',
                alignItems: 'center',
                mt: !brands?.length ? 0 : 1
            }}
        >
            <CoreTypography variant="overline">
                <FormattedMessage
                    description="Label for company brands"
                    defaultMessage="Brands: "
                />
            </CoreTypography>
            {brands?.map((brand) => {
                return (
                    <Chip
                        data-testid={`brand-chip-${brand.name}`}
                        key={brand.name}
                        label={brand.name}
                        sx={{
                            borderRadius: '0px',
                            fontSize: '1.5rem',
                            fontFamily: theme.typography.h6,
                            textTransform: 'uppercase'
                        }}
                        avatar={<Avatar src={brand.imageUrl} />}
                        deleteIcon={<EditBrandButton brand={brand} />}
                        onDelete={() => {}}
                    />
                )
            })}
            <ModalButton
                modalButtonVariant={ModalButtonVariant.Icon}
                iconName={'plus'}
                ModalComponent={AddBrandModal}
                size="medium"
                buttonLabel={intl.formatMessage(messages.addBrandLabel)}
            />
        </Stack>
    )
}
