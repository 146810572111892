import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Box, Avatar, Stack } from '@mui/material'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'
import { Brand } from '../../../graphql/generated/autogenerated'
import { ModalButton, ModalButtonProps } from '../../buttons/index'
import { ModalButtonVariant } from '../../../types/index'

const messages = defineMessages({
    editBrandLabel: {
        defaultMessage: 'Edit brand',
        description: 'Label for edit brand button'
    }
})

export interface BrandListItemProps {
    brand: Partial<Brand>
    brandList: Partial<Brand>[]
    EditModal: React.FC<ModalButtonProps>
}

export const BrandListItem: React.FC<BrandListItemProps> = ({
    brand,
    brandList,
    EditModal
}) => {
    const intl = useIntl()

    return (
        <Box
            data-testid={`brand-item-${brand.name}`}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 1
            }}
        >
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                <Avatar src={brand.imageUrl} data-testid="brand-avatar">
                    {!brand.imageUrl && <LeafIcon icon={'image'} />}
                </Avatar>
                <CoreTypography>{brand.name}</CoreTypography>
            </Stack>
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                {brand.isPublic ? (
                    <LeafIcon icon={'eye'} />
                ) : (
                    <LeafIcon icon={'eye-slash'} />
                )}
                <ModalButton
                    modalButtonVariant={ModalButtonVariant.Icon}
                    buttonLabel={intl.formatMessage(messages.editBrandLabel)}
                    iconName={'pen'}
                    ModalComponent={EditModal}
                    trigger={{ brand, brandList }}
                />
            </Stack>
        </Box>
    )
}
