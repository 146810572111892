import React from 'react'
import { useIntl, MessageDescriptor } from 'react-intl'
import { CoreTab } from '@thriveglobal/thrive-web-leafkit'
import { Box, Tabs, styled, Divider } from '@mui/material'

//Show both scroll buttons on mobile
const TabContainer = styled(Tabs)({
    '.MuiTabs-scrollButtons.Mui-disabled': {
        opacity: '0.3'
    }
})

export interface TabOption {
    value: string
    label: MessageDescriptor
    panel?: React.ReactNode
}

export interface TabBarProps {
    value: string
    tabs: TabOption[]
    handleChange?: (event: React.SyntheticEvent, newValue: string) => void
    scrollable?: boolean
}

export const TabBar: React.FC<TabBarProps> = ({
    value,
    handleChange,
    tabs,
    scrollable = false
}) => {
    const intl = useIntl()
    return (
        <Box data-testid="tab-bar">
            <TabContainer
                value={value}
                onChange={handleChange}
                variant={scrollable ? 'scrollable' : 'standard'}
                allowScrollButtonsMobile
            >
                {tabs.map((tab) => {
                    return (
                        <CoreTab
                            value={tab.value}
                            key={tab.value}
                            label={intl.formatMessage(tab.label)}
                            id={`tab-${tab.value}`}
                            data-testid={`tab-${tab.value}`}
                            aria-controls={`tabpanel-${tab.value}`}
                        />
                    )
                })}
            </TabContainer>
            <Divider />
        </Box>
    )
}
