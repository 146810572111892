import React, { useState } from 'react'
import { ModalButtonVariant } from '../../../types'
import { useIntl, defineMessages } from 'react-intl'
import { ModalButton, ModalProps } from '../../buttons'
import { SignupMethodInfoModal } from './SignupMethodInfoModal'
import {
    IdpConfig,
    IdpType,
    useChangeIdpConfigFriendsAndFamilyOnlyStatusMutation,
    useChangePriorityMutation
} from '../../../graphql/generated/autogenerated'
import {
    TableCell,
    TableRow,
    Select,
    MenuItem,
    FormControlLabel,
    Switch
} from '@mui/material'
import { CoreTypography, useTheme } from '@thriveglobal/thrive-web-leafkit'

const messages = defineMessages({
    enabledLabel: {
        defaultMessage: 'Enabled',
        description: 'Label for enabled sign-up method'
    },
    disabledLabel: {
        defaultMessage: 'Disabled',
        description: 'Label for disabled sign-up method'
    },
    enableButtonLabel: {
        defaultMessage: 'Enable',
        description: 'Label for button to enable sign-up method'
    },
    disableButtonLabel: {
        defaultMessage: 'Disable',
        description: 'Label for button to disable signup method'
    },
    setupButtonLabel: {
        defaultMessage: 'Setup',
        description: 'Label for button to setup signup method'
    },
    notInUseLabel: {
        defaultMessage: 'Not in use',
        description: 'Label signup method that has not been setup'
    },
    moreInfoLabel: {
        defaultMessage: 'More info',
        description: 'Label for more info button'
    },
    priorityLabel: {
        defaultMessage: 'Priority',
        description: 'Label for more priority text field'
    },
    priorityNotAvailableLabel: {
        defaultMessage: 'N/A',
        description: 'Label for more priority text field'
    },
    priorityNoValueSelected: {
        defaultMessage: 'None',
        description: 'Label when an idp has no priority'
    },
    friendsAndFamilyOnlyNotAvailableLabel: {
        defaultMessage: 'N/A',
        description: 'Label for fnf only not available'
    },
    friendsAndFamilyOnlyOnLabel: {
        defaultMessage: 'On',
        description: 'Label for fnf only enabled'
    },
    friendsAndFamilyOnlyOffLabel: {
        defaultMessage: 'Off',
        description: 'Label for fnf only disabled'
    }
})

const NO_PRIORITY_VALUE = 'NONE'
type NoPriorityType = 'NONE'

export interface SignupMethodListItemProps {
    name: string
    platform: string
    idps: IdpConfig[]
    idpType: string
    SetupModal?: React.FC<ModalProps>
    EnableModal?: React.FC<ModalProps>
    DisableModal?: React.FC<ModalProps>
}

const PRIORITY_OPTIONS = [
    { value: null, text: 'None' },
    { value: -1, text: '-1' },
    { value: 0, text: '0' },
    { value: 1, text: '1' },
    { value: 2, text: '2' },
    { value: 3, text: '3' },
    { value: 4, text: '4' },
    { value: 5, text: '5' }
]

function getPriority(idp: IdpConfig | undefined): number | null {
    if (!idp) {
        return null
    }
    try {
        const configuration = JSON.parse(idp.configuration)
        const priority = configuration?.priority
        if (priority === 0 || priority) {
            return priority
        }
        return null
    } catch {
        return null
    }
}

function getFnFStatus(idp: IdpConfig | undefined): boolean | null {
    if (!idp) {
        return null
    }
    try {
        const configuration = JSON.parse(idp.configuration)
        return configuration?.friendsAndFamilyOnly
    } catch {
        return null
    }
}

export const SignupMethodListItem: React.FC<SignupMethodListItemProps> = ({
    name,
    platform,
    idps,
    idpType,
    SetupModal,
    EnableModal,
    DisableModal
}) => {
    const intl = useIntl()
    const theme = useTheme()

    const config = idps.find((idp) => idp.idpType === idpType)
    const friendsAndFamilyOnly = getFnFStatus(config)
    const [fnfOnly, setFnfOnly] = useState<boolean>(
        friendsAndFamilyOnly ? true : false
    )
    const configPriority = getPriority(config)
    const [selectedPriority, setSelectedPriority] = useState<
        number | NoPriorityType
    >(configPriority === null ? NO_PRIORITY_VALUE : configPriority)

    const [updatePriority] = useChangePriorityMutation()
    const onPriorityChange = (event) => {
        const newPriority = event.target.value
        setSelectedPriority(newPriority)
        const variables = {
            companyId: config.companyId,
            idpType: config.idpType as IdpType
        }
        if (newPriority !== null) {
            variables['priority'] = newPriority
        }
        updatePriority({
            variables,
            onError: () => {
                setSelectedPriority(
                    configPriority === null ? NO_PRIORITY_VALUE : configPriority
                )
            }
        })
    }

    const [updateFnFStatus] =
        useChangeIdpConfigFriendsAndFamilyOnlyStatusMutation()
    const onFnFChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setFnfOnly(event.target.checked)
        updateFnFStatus({
            variables: {
                companyId: config.companyId,
                idpType: config.idpType as IdpType,
                friendsAndFamilyOnly: event.target.checked
            },
            onError: () => {
                setFnfOnly(!event.target.checked)
            }
        })
    }

    return config ? (
        <TableRow>
            <TableCell sx={{ backgroundColor: theme.palette.secondary.light }}>
                <CoreTypography
                    sx={{ color: theme.palette.secondary.contrastText }}
                    variant="body1"
                >
                    {platform}
                </CoreTypography>
            </TableCell>
            <TableCell>
                <CoreTypography variant="body1">{name}</CoreTypography>
            </TableCell>
            <TableCell>
                <CoreTypography variant="body1">
                    {idpType === IdpType.KeycloakDirectPassword ? (
                        <FormControlLabel
                            checked={fnfOnly}
                            control={
                                <Switch
                                    data-testid="fnf-switch"
                                    color="primary"
                                />
                            }
                            label={
                                fnfOnly
                                    ? intl.formatMessage(
                                          messages.friendsAndFamilyOnlyOnLabel
                                      )
                                    : intl.formatMessage(
                                          messages.friendsAndFamilyOnlyOffLabel
                                      )
                            }
                            labelPlacement="end"
                            onChange={onFnFChange}
                        />
                    ) : (
                        <CoreTypography variant="body1">
                            {intl.formatMessage(
                                messages.friendsAndFamilyOnlyNotAvailableLabel
                            )}
                        </CoreTypography>
                    )}
                </CoreTypography>
            </TableCell>
            <TableCell size="small" align="center">
                <ModalButton
                    modalButtonVariant={ModalButtonVariant.Icon}
                    variant="outlined"
                    size="large"
                    buttonLabel={intl.formatMessage(messages.moreInfoLabel)}
                    iconName={'circle-info'}
                    ModalComponent={SignupMethodInfoModal}
                    modalSize="lg"
                    trigger={config}
                />
            </TableCell>
            <TableCell data-testid="priority-cell">
                <Select
                    required
                    fullWidth
                    value={selectedPriority}
                    onChange={onPriorityChange}
                    label={intl.formatMessage(messages.priorityLabel)}
                    labelId="priority"
                >
                    {PRIORITY_OPTIONS.map((option) => (
                        <MenuItem
                            key={`${config.idpType}-${option.value}`}
                            value={option.value}
                        >
                            {option.text === NO_PRIORITY_VALUE
                                ? intl.formatMessage(
                                      messages.priorityNoValueSelected
                                  )
                                : option.text}
                        </MenuItem>
                    ))}
                </Select>
            </TableCell>
            <TableCell align="center">
                <CoreTypography
                    variant="overline"
                    sx={{
                        color: config?.enabled
                            ? 'green'
                            : theme.palette.error.main
                    }}
                >
                    {config?.enabled
                        ? intl.formatMessage(messages.enabledLabel)
                        : intl.formatMessage(messages.disabledLabel)}
                </CoreTypography>
            </TableCell>
            <TableCell align="center">
                {EnableModal && DisableModal && (
                    <ModalButton
                        modalButtonVariant={ModalButtonVariant.Default}
                        variant="outlined"
                        size="medium"
                        buttonText={
                            config?.enabled
                                ? intl.formatMessage(
                                      messages.disableButtonLabel
                                  )
                                : intl.formatMessage(messages.enableButtonLabel)
                        }
                        ModalComponent={
                            config.enabled ? DisableModal : EnableModal
                        }
                        trigger={config}
                    />
                )}
            </TableCell>
        </TableRow>
    ) : (
        <TableRow>
            <TableCell sx={{ backgroundColor: theme.palette.secondary.light }}>
                <CoreTypography
                    sx={{ color: theme.palette.secondary.contrastText }}
                    variant="body1"
                >
                    {platform}
                </CoreTypography>
            </TableCell>
            <TableCell>
                <CoreTypography variant="body1">{name}</CoreTypography>
            </TableCell>
            <TableCell>
                <CoreTypography variant="body1">
                    {intl.formatMessage(
                        messages.friendsAndFamilyOnlyNotAvailableLabel
                    )}
                </CoreTypography>
            </TableCell>
            <TableCell size="small"></TableCell>
            <TableCell>
                {intl.formatMessage(messages.priorityNotAvailableLabel)}
            </TableCell>
            <TableCell align="center">
                <CoreTypography
                    variant="overline"
                    color={theme.palette.text.primary}
                    sx={{ mt: 1 }}
                >
                    {intl.formatMessage(messages.notInUseLabel)}
                </CoreTypography>
            </TableCell>
            <TableCell align="center">
                <ModalButton
                    modalButtonVariant={ModalButtonVariant.Default}
                    buttonText={intl.formatMessage(messages.setupButtonLabel)}
                    variant="outlined"
                    size="medium"
                    ModalComponent={SetupModal}
                />
            </TableCell>
        </TableRow>
    )
}
