import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import { CompanyStatus } from '../../../graphql/generated/autogenerated'
import { InputLabel, FormControl, MenuItem, Select } from '@mui/material'

const messages = defineMessages({
    statusLabel: {
        defaultMessage: 'Status',
        description: 'Label for company status field'
    },
    activeCompanyLabel: {
        defaultMessage: 'Active',
        description: 'Label active company option'
    },
    inactiveCompanyLabel: {
        defaultMessage: 'Inactive',
        description: 'Label for inactive company option'
    },
    demoCompanyLabel: {
        defaultMessage: 'Demo',
        description: 'Label for demo company option'
    },
    testCompanyLabel: {
        defaultMessage: 'Test',
        description: 'Label for test company option'
    },
    prospectCompanyLabel: {
        defaultMessage: 'Prospect',
        description: 'Label for prospect company option'
    }
})

export const StatusSelector: React.FC = () => {
    const intl = useIntl()
    const { control } = useFormContext()
    return (
        <>
            <FormControl variant="outlined" fullWidth>
                <InputLabel id="status-select-label">
                    {intl.formatMessage(messages.statusLabel)}
                </InputLabel>
                <Controller
                    render={({ field }) => (
                        <Select
                            fullWidth
                            labelId="status-select-label"
                            id="status-select"
                            value={field.value}
                            inputRef={field.ref}
                            onChange={field.onChange}
                            label={intl.formatMessage(messages.statusLabel)}
                        >
                            <MenuItem
                                value={CompanyStatus.Active}
                                aria-label={intl.formatMessage(
                                    messages.activeCompanyLabel
                                )}
                            >
                                {intl.formatMessage(
                                    messages.activeCompanyLabel
                                )}
                            </MenuItem>
                            <MenuItem
                                value={CompanyStatus.Inactive}
                                aria-label={intl.formatMessage(
                                    messages.inactiveCompanyLabel
                                )}
                            >
                                {intl.formatMessage(
                                    messages.inactiveCompanyLabel
                                )}
                            </MenuItem>
                            <MenuItem
                                value={CompanyStatus.Demo}
                                aria-label={intl.formatMessage(
                                    messages.demoCompanyLabel
                                )}
                            >
                                {intl.formatMessage(messages.demoCompanyLabel)}
                            </MenuItem>
                            <MenuItem
                                value={CompanyStatus.Test}
                                aria-label={intl.formatMessage(
                                    messages.testCompanyLabel
                                )}
                            >
                                {intl.formatMessage(messages.testCompanyLabel)}
                            </MenuItem>
                            <MenuItem
                                value={CompanyStatus.Prospect}
                                aria-label={intl.formatMessage(
                                    messages.prospectCompanyLabel
                                )}
                            >
                                {intl.formatMessage(
                                    messages.prospectCompanyLabel
                                )}
                            </MenuItem>
                        </Select>
                    )}
                    name="status"
                    control={control}
                />
            </FormControl>
        </>
    )
}
