import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box
} from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'

interface ModalSuccessContentProps {
    title: string
    description: string
    handleClose: () => void
}

export const ModalSuccessContent: React.FC<ModalSuccessContentProps> = ({
    title,
    description,
    handleClose
}) => {
    return (
        <Box
            sx={{
                height: '100%',
                minHeight: '400px',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>{title}</DialogTitle>
            <DialogContent>
                <CoreAlert severity="success">
                    <CoreTypography variant="body2">
                        {description}
                    </CoreTypography>
                </CoreAlert>
            </DialogContent>
            <DialogActions>
                <Box sx={{ p: 2 }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleClose}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to close modal"
                                defaultMessage="Close"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            </DialogActions>
        </Box>
    )
}
