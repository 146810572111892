import React from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Box, Avatar, IconButton, Stack } from '@mui/material'
import { removeEmailDomain } from '../../../slices/createCompany'
import { useAppDispatch } from '@thriveglobal/thrive-web-core'
import { CoreTypography, LeafIcon } from '@thriveglobal/thrive-web-leafkit'

const messages = defineMessages({
    deleteEmailLabel: {
        defaultMessage: 'delete email domain',
        description: 'Label for delete email domain button'
    }
})

export interface EmailDomainListItemProps {
    emailDomain: string
}

export const EmailDomainListItem: React.FC<EmailDomainListItemProps> = ({
    emailDomain
}) => {
    const intl = useIntl()
    const dispatch = useAppDispatch()

    const handleDeleteClick = () => {
        dispatch(removeEmailDomain(emailDomain))
    }

    return (
        <Box
            data-testid={`email-item-${emailDomain}`}
            sx={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                py: 1
            }}
        >
            <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                <Avatar data-testid="email-avatar" variant="square">
                    {<LeafIcon icon={'envelope'} />}
                </Avatar>
                <CoreTypography>{emailDomain}</CoreTypography>
            </Stack>
            <IconButton
                color="primary"
                onClick={handleDeleteClick}
                aria-label={intl.formatMessage(messages.deleteEmailLabel)}
            >
                <LeafIcon icon={'trash'} />
            </IconButton>
        </Box>
    )
}
