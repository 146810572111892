import { PixelCrop, centerCrop, makeAspectCrop } from 'react-image-crop'

export interface BlobImageStorageCloudflarePostResponseResult {
    errors: any[]
    messages: any[]
    result?: {
        filename: string
        id: string
        requireSignedURLs: boolean
        uploaded: string
        variants: string[]
    }
    success: boolean
}

export const centerAspectCrop = (
    mediaWidth: number,
    mediaHeight: number,
    aspect: number
) => {
    return centerCrop(
        makeAspectCrop(
            {
                unit: '%',
                width: 90
            },
            aspect,
            mediaWidth,
            mediaHeight
        ),
        mediaWidth,
        mediaHeight
    )
}

export const uploadImage = async (url: string, blob: Blob): Promise<string> => {
    const formData = new FormData()
    formData.append('file', blob, 'logoImage')
    const options = {
        method: 'POST',
        body: formData
    }

    const response = await fetch(url, options)
    const data =
        (await response.json()) as BlobImageStorageCloudflarePostResponseResult
    if (data.success) {
        const imageUrl = data.result.variants?.find((variant) =>
            variant.includes('/xs')
        )
        return imageUrl
    } else {
        const error = new Error('Unable to get image url')
        throw error
    }
}

export const updateTransformedImageCanvas = async (
    image: HTMLImageElement,
    canvas: HTMLCanvasElement,
    crop: PixelCrop,
    scale = 1
) => {
    const ctx = canvas.getContext('2d')

    if (!ctx) {
        throw new Error('No 2d context')
    }

    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    const pixelRatio = window.devicePixelRatio

    canvas.width = Math.floor(crop.width * scaleX * pixelRatio)
    canvas.height = Math.floor(crop.height * scaleY * pixelRatio)

    ctx.scale(pixelRatio, pixelRatio)
    ctx.imageSmoothingQuality = 'high'

    const cropX = crop.x * scaleX
    const cropY = crop.y * scaleY

    const centerX = image.naturalWidth / 2
    const centerY = image.naturalHeight / 2

    ctx.save()

    // Move the crop origin to the canvas origin (0,0)
    ctx.translate(-cropX, -cropY)
    // Move the origin to the center of the original position
    ctx.translate(centerX, centerY)
    // Scale the image
    ctx.scale(scale, scale)
    // Move the center of the image to the origin (0,0)
    ctx.translate(-centerX, -centerY)
    ctx.drawImage(
        image,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight,
        0,
        0,
        image.naturalWidth,
        image.naturalHeight
    )

    ctx.restore()
}
