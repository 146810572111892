import {
    Brand,
    Company,
    CompanyStatus,
    LicenseType,
    SignupCode,
    SubscriptionType,
    UserSubscription
} from '../../graphql/generated/autogenerated'
import { Slice, createSlice, PayloadAction } from '@reduxjs/toolkit'
import { CreateCompanyTabs } from '../../pages/CreateCompany/CreateCompany'
import { Order } from 'src/utils'

export interface SearchState {
    search: string
    searchBy: keyof Company
    order: Order
    orderBy: keyof Company
    statusFilters: CompanyStatus[]
}
export interface CreateCompanyState {
    activeTab: CreateCompanyTabs
    company: Partial<Company>
    subscriptions: Partial<UserSubscription>[]
    selectedSubscriptionId: string
    signupCodes: Partial<SignupCode>[]
    emailDomains: string[]
    searchState: SearchState
}

export const initialState: CreateCompanyState = {
    activeTab: CreateCompanyTabs?.CompanyAndBrands,
    company: {
        name: '',
        imageUrl: '',
        brands: [],
        seatsAllowed: 0,
        isPublic: false,
        status: CompanyStatus.Active
    },
    //ids here are just temporary to link to signup codes
    subscriptions: [
        {
            id: '44eb4c9a-37ed-4e55-a1c8-5201c8c0c9c7',
            internalDescription: 'main',
            isDefault: true,
            enabled: true,
            license: LicenseType.Standard,
            subscriptionType: SubscriptionType.Employee
        },
        {
            id: '026f3e19-d4cb-40b5-99cf-22270786cd4f',
            internalDescription: 'friends and family',
            isDefault: false,
            enabled: true,
            license: LicenseType.Standard,
            subscriptionType: SubscriptionType.FriendsAndFamily
        }
    ],
    selectedSubscriptionId: '',
    signupCodes: [],
    emailDomains: [],
    searchState: {
        search: '',
        searchBy: 'name',
        order: 'asc',
        orderBy: 'name',
        statusFilters: [CompanyStatus.Active]
    }
}

export const CreateCompanySlice: Slice = createSlice({
    name: 'createCompany',
    initialState,
    reducers: {
        //Search State
        setSearch: (state, { payload }: PayloadAction<string>) => {
            state.searchState.search = payload
        },
        setSearchBy: (state, { payload }: PayloadAction<keyof Company>) => {
            state.searchState.searchBy = payload
        },
        setOrder: (state, { payload }: PayloadAction<Order>) => {
            state.searchState.order = payload
        },
        setOrderBy: (state, { payload }: PayloadAction<keyof Company>) => {
            state.searchState.orderBy = payload
        },
        setStatusFilters: (
            state,
            { payload }: PayloadAction<CompanyStatus[]>
        ) => {
            state.searchState.statusFilters = payload
        },

        //Navigation
        setActiveTab: (state, { payload }: PayloadAction<any>) => {
            state.activeTab = payload
        },

        //Company
        setCompanyName: (state, { payload }: PayloadAction<any>) => {
            state.company.name = payload
        },
        setCompanyLogo: (state, { payload }: PayloadAction<any>) => {
            state.company.imageUrl = payload
        },
        setSeatsAllowed: (state, { payload }: PayloadAction<any>) => {
            state.company.seatsAllowed = payload
        },
        setIsPublic: (state, { payload }: PayloadAction<boolean>) => {
            state.company.isPublic = payload
        },
        setCompanyStatus: (state, { payload }: PayloadAction<any>) => {
            state.company.status = payload
        },

        //Brands
        addBrand: (state, { payload }: PayloadAction<Brand>) => {
            state.company.brands.push(payload)
        },
        editBrand: (state, { payload }: PayloadAction<any>) => {
            const brandIndex = state.company.brands.findIndex(
                (brand) => brand.name === payload.originalName
            )

            if (brandIndex !== -1) {
                state.company.brands = state.company.brands.map(
                    (brand, index) => {
                        return index === brandIndex ? payload.brand : brand
                    }
                )
            }
        },
        removeBrand: (state, { payload }: PayloadAction<string>) => {
            state.company.brands = state.company.brands.filter(
                (brand) => brand.name !== payload
            )
        },

        //Subscriptions
        setSelectedSubscriptionId: (state, { payload }: PayloadAction<any>) => {
            state.selectedSubscriptionId = payload
        },
        addSubscription: (
            state,
            { payload }: PayloadAction<Partial<UserSubscription>>
        ) => {
            //if subscription is set as default, make sure it is the only one set as default
            if (payload.isDefault) {
                state.subscriptions.map((sub) => {
                    sub.isDefault = false
                })
            }
            state.subscriptions.push(payload)
            state.selectedSubscriptionId = payload.id
        },
        removeSubscription: (state, { payload }: PayloadAction<string>) => {
            state.subscriptions = state.subscriptions.filter(
                (sub) => sub.id !== payload
            )
            state.signupCodes = state.signupCodes.filter(
                (code) => code.subscriptionId !== payload
            )
            if (state.selectedSubscriptionId === payload) {
                state.selectedSubscriptionId = ''
            }
        },
        editSubscription: (
            state,
            { payload }: PayloadAction<Partial<UserSubscription>>
        ) => {
            if (payload.isDefault) {
                state.subscriptions.map((sub) => {
                    sub.isDefault = false
                })
            }

            const subIndex = state.subscriptions.findIndex(
                (sub) => sub.id === payload.id
            )

            if (subIndex !== -1) {
                state.subscriptions = state.subscriptions.map((sub, index) => {
                    return index === subIndex ? { ...sub, ...payload } : sub
                })
            }
        },

        //Signup Codes
        addSignupCode: (
            state,
            { payload }: PayloadAction<Partial<SignupCode>>
        ) => {
            state.signupCodes.push(payload)
        },
        removeSignupCode: (state, { payload }: PayloadAction<string>) => {
            state.signupCodes = state.signupCodes.filter(
                (code) => code.code !== payload
            )
        },
        editSignupCode: (state, { payload }: PayloadAction<any>) => {
            const codeIndex = state.signupCodes.findIndex(
                (code) => code.code === payload.originalCode
            )

            if (codeIndex !== -1) {
                state.signupCodes = state.signupCodes.map((code, index) => {
                    return index === codeIndex
                        ? {
                              subscriptionId: payload.subscriptionId,
                              code: payload.code,
                              validFrom: payload.validFrom,
                              validUntil: payload.validUntil
                          }
                        : code
                })
            }
        },

        //Email Domains
        addEmailDomain: (state, { payload }: PayloadAction<string[]>) => {
            const removeDuplicates = new Set([
                ...state.emailDomains,
                ...payload
            ])
            state.emailDomains = Array.from(removeDuplicates)
        },
        removeEmailDomain: (state, { payload }: PayloadAction<string>) => {
            state.emailDomains = state.emailDomains.filter(
                (domain) => domain !== payload
            )
        },

        //Clear
        resetState: (state) => {
            return { ...initialState, searchState: { ...state.searchState } }
        }
    }
})

export const {
    setSearch,
    setSearchBy,
    setOrder,
    setOrderBy,
    setStatusFilters,
    addBrand,
    resetState,
    removeBrand,
    editBrand,
    setIsPublic,
    setActiveTab,
    setCompanyName,
    setCompanyLogo,
    setSeatsAllowed,
    addSubscription,
    removeSubscription,
    editSubscription,
    setSelectedSubscriptionId,
    addSignupCode,
    removeSignupCode,
    editSignupCode,
    addEmailDomain,
    removeEmailDomain,
    setCompanyStatus
} = CreateCompanySlice.actions as any

export default CreateCompanySlice.reducer as any
