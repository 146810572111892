import React, { useState } from 'react'
import { ModalProps } from '../../buttons'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { DialogTitle, DialogContent, IconButton } from '@mui/material'
import { ConfirmationSnackBar } from '../../features'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'

const messages = defineMessages({
    copyConfigButtonLabel: {
        defaultMessage: 'Copy configuration to clipboard',
        description: 'Label for button to copy configuration'
    }
})

export const SignupMethodInfoModal: React.FC<ModalProps> = ({ trigger }) => {
    const intl = useIntl()

    //make idpConfig more readable
    const data = { ...trigger }
    const parsedConfig = JSON.parse(trigger.configuration)
    data.configuration = parsedConfig
    const config = JSON.stringify(data, null, 2)

    const [copyConfirmationOpen, setCopyConfirmationOpen] = useState(false)

    const handleCopyConfigConfirmationClose = () => {
        setCopyConfirmationOpen(false)
    }

    const handleCopyConfigClick = () => {
        navigator.clipboard.writeText(JSON.stringify(trigger))
        setCopyConfirmationOpen(true)
    }

    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>
                <FormattedMessage
                    defaultMessage="IdpConfig"
                    description="Title for signup method info modal"
                />
                <IconButton
                    color="primary"
                    onClick={handleCopyConfigClick}
                    aria-label={intl.formatMessage(
                        messages.copyConfigButtonLabel
                    )}
                >
                    <LeafIcon icon={'copy'} />
                </IconButton>
            </DialogTitle>
            <DialogContent
                sx={{
                    pre: {
                        whiteSpace: 'pre-wrap'
                    }
                }}
            >
                <pre>{config}</pre>
            </DialogContent>
            <ConfirmationSnackBar
                isOpen={copyConfirmationOpen}
                handleClose={handleCopyConfigConfirmationClose}
                message={
                    <>
                        <FormattedMessage
                            defaultMessage="Configuration copied to clipboard"
                            description="Confirmation text shown when configuration is copied to clipboard"
                        />
                    </>
                }
            />
        </>
    )
}
