import React from 'react'
import { FeatureFlagsListItem } from '../../list-items'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material'

const messages = defineMessages({
    featureFlagTableLabel: {
        defaultMessage: 'Feature Flag Table'
    }
})

export interface FeatureFlag {
    featureName: string
    flagName: string
    enabled: boolean
}

export interface FeatureFlagsListProps {
    featureFlags: FeatureFlag[]
}

export const FeatureFlagsList: React.FC<FeatureFlagsListProps> = ({
    featureFlags
}) => {
    const { formatMessage } = useIntl()
    return (
        <Table
            data-testid="featureflags-list"
            aria-label={formatMessage(messages.featureFlagTableLabel)}
        >
            <TableHead>
                <TableRow>
                    <TableCell>
                        <FormattedMessage
                            description="Label for feature name column"
                            defaultMessage="Feature Name"
                        />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage
                            description="Label for feature flag column"
                            defaultMessage="Feature Flag"
                        />
                    </TableCell>
                    <TableCell>
                        <FormattedMessage
                            description="Label for feature name column"
                            defaultMessage="Enabled"
                        />
                    </TableCell>
                </TableRow>
            </TableHead>
            <TableBody>
                {featureFlags.map((flag, index) => (
                    <FeatureFlagsListItem featureFlag={flag} key={index} />
                ))}
            </TableBody>
        </Table>
    )
}
