import React, { ReactNode } from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import { TextField } from '@mui/material'
import { validateUuid } from '../../../utils'

const messages = defineMessages({
    tenantIdHelperText: {
        defaultMessage: 'Enter tenant ID to enable MS Teams',
        description: 'Helper text for tenant ID input'
    },
    tenantIdRequiredError: {
        defaultMessage: 'Tenant ID is required.',
        description: 'Error text for required tenant ID input'
    },
    tenantIdWrongFormatError: {
        defaultMessage: 'Invalid Tenant ID.',
        description: 'Error text for invalid UUID tenant ID input'
    },
    tenantIdInputLabel: {
        defaultMessage: 'Tenant ID',
        description: 'Label for tenant id input'
    }
})

export interface TenantIdFormInputs {
    tenantId: string
}

export const TenantIdInput: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <>
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        spellCheck="false"
                        id="idp-id"
                        variant="outlined"
                        inputRef={field.ref}
                        value={field.value}
                        error={!!errors?.tenantId}
                        onChange={field.onChange}
                        label={intl.formatMessage(messages.tenantIdInputLabel)}
                        helperText={
                            <>
                                {errors?.tenantId?.message
                                    ? errors?.tenantId?.message
                                    : intl.formatMessage(
                                          messages.tenantIdHelperText
                                      )}
                            </>
                        }
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                control={control}
                rules={{
                    required: intl.formatMessage(
                        messages.tenantIdRequiredError
                    ),

                    validate: {
                        validUuid: (value) => {
                            if (!validateUuid(value))
                                return intl.formatMessage(
                                    messages.tenantIdWrongFormatError
                                )
                        }
                    }
                }}
                name="tenantId"
            />
        </>
    )
}
