import React, { useState } from 'react'
import { addBrand } from '../../../../slices/createCompany'
import { BrandListItem } from '../../../../components/list-items'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { Button, Divider, Grid } from '@mui/material'
import { useAppSelector, useAppDispatch } from '@thriveglobal/thrive-web-core'
import BrandInput, {
    BrandFormInputs
} from '../../../../components/inputs/BrandInput/BrandInput'
import { EditBrandModal } from './EditBrandModal/EditBrandModal'
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form'
import { defineMessages, useIntl, FormattedMessage } from 'react-intl'
import { LogoInput } from '../../../../components/inputs'

const messages = defineMessages({
    addBrandsLabel: {
        defaultMessage: 'Add brand',
        description: 'Label for button to add brand'
    }
})

const Brands: React.FC = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const [logoUrl, setLogoUrl] = useState('')

    const createCompany = useAppSelector((state) => state.createCompany)
    const brands = createCompany?.company?.brands

    const defaultValues = {
        name: '',
        isPublic: true
    }

    const formMethods = useForm<BrandFormInputs>({
        defaultValues: defaultValues,
        reValidateMode: 'onSubmit'
    })

    const { handleSubmit, reset } = formMethods

    const handleAddBrandClick: SubmitHandler<BrandFormInputs> = async (
        data,
        event
    ) => {
        const newBrand = {
            name: data.name.trim(),
            imageUrl: logoUrl || null,
            // Reverse checkbox value since hide = checkbox: true, isPublic:false
            // and show = checkbox:false, isPublic:true
            isPublic: !data.isPublic
        }
        dispatch(addBrand(newBrand))
        setLogoUrl('')
        reset()
    }

    return (
        <>
            <Grid item xs={12}>
                <CoreTypography variant={'h4'}>
                    <FormattedMessage
                        description="Heading for Add Associated Brands section"
                        defaultMessage="Add associated brands"
                    />
                </CoreTypography>
            </Grid>
            <Grid
                item
                container
                data-testid="brands-section"
                component="form"
                id="brands-form"
                onSubmit={handleSubmit(handleAddBrandClick)}
                noValidate
                xs={12}
            >
                <FormProvider {...formMethods}>
                    <Grid item xs={12} md={7}>
                        <LogoInput
                            type="brand"
                            logoUrl={logoUrl}
                            setLogoUrl={setLogoUrl}
                        />
                    </Grid>
                    <BrandInput />
                </FormProvider>
                <Grid item sx={{ display: 'flex', alignItems: 'center' }}>
                    <Button
                        variant="outlined"
                        color="primary"
                        aria-label={intl.formatMessage(messages.addBrandsLabel)}
                        type="submit"
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to add brand"
                                defaultMessage="Add"
                            />
                        </CoreTypography>
                    </Button>
                </Grid>
            </Grid>
            {!!brands?.length && (
                <Grid item xs={12}>
                    <Divider />
                </Grid>
            )}
            <Grid item xs={12} md={7}>
                {brands.map((brand) => (
                    <BrandListItem
                        key={brand.name}
                        brand={brand}
                        brandList={brands}
                        EditModal={EditBrandModal}
                    />
                ))}
            </Grid>
        </>
    )
}

export default Brands
