import React from 'react'
import { getDates } from '../../../utils'
import { ModalButtonVariant } from '../../../types'
import { useIntl, defineMessages } from 'react-intl'
import { Link, TableCell, TableRow } from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'
import { ModalButton, ModalButtonProps } from '../../buttons'
import { SignupCode } from '../../../graphql/generated/autogenerated'

const messages = defineMessages({
    editLabel: {
        defaultMessage: 'edit signup code',
        description: 'Aria label to describe edit button'
    }
})

export interface SignupCodeListItemProps {
    companyName?: string
    item: Partial<SignupCode>
    EditModal: React.FC<ModalButtonProps>
    shouldLink?: boolean
}

export const SignupCodeListItem: React.FC<SignupCodeListItemProps> = ({
    item,
    EditModal,
    companyName = null,
    shouldLink = false
}) => {
    const intl = useIntl()
    const theme = useTheme()

    const encodedCompany = companyName && encodeURIComponent(companyName)
    const encodedCode = encodeURIComponent(item.code)
    const path = `${process.env.EMAIL_SIGNUP_ROUTE}${encodedCompany}?groupCode=${encodedCode}`

    const startDateParts = item.validFrom?.substring(0, 10)?.split('-')
    const startDate =
        startDateParts &&
        new Date(startDateParts[0], startDateParts[1] - 1, startDateParts[2])

    const isExpired = getDates().today > item.validUntil
    const endDateParts = item.validUntil?.substring(0, 10)?.split('-')
    const endDate =
        endDateParts &&
        new Date(endDateParts[0], endDateParts[1] - 1, endDateParts[2])

    return (
        <TableRow
            data-testid={`table-row-${item.code}`}
            key={item.code}
            sx={{
                '&:last-child td, &:last-child th': {
                    border: 0
                }
            }}
        >
            <TableCell component="th" scope="code">
                {shouldLink ? (
                    <Link href={path} target="_blank" rel="noopener">
                        {item.code}
                    </Link>
                ) : (
                    item.code
                )}
            </TableCell>
            <TableCell>
                {intl.formatDate(startDate, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                })}
            </TableCell>
            <TableCell
                sx={{ color: isExpired ? theme.palette.error.main : 'inherit' }}
            >
                {intl.formatDate(endDate, {
                    year: 'numeric',
                    month: 'short',
                    day: 'numeric'
                })}
            </TableCell>
            <TableCell>
                <ModalButton
                    modalButtonVariant={ModalButtonVariant.Icon}
                    buttonLabel={intl.formatMessage(messages.editLabel)}
                    iconName={'pen'}
                    ModalComponent={EditModal}
                    trigger={item}
                />
            </TableCell>
        </TableRow>
    )
}
