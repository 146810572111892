import React from 'react'
import { Box, Button, Stack } from '@mui/material'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import {
    CoreTypography,
    LeafIcon,
    useTheme
} from '@thriveglobal/thrive-web-leafkit'
import { LogoModal } from '../../elements/LogoModal/LogoModal'

export interface LogoInputProps {
    logoUrl?: string
    type: 'company' | 'brand'
    setLogoUrl?: (url: string) => void
}

const messages = defineMessages({
    logoPreviewLabel: {
        defaultMessage: 'Logo image preview',
        description: 'Label for logo image preview'
    },
    uploadLogoLabel: {
        defaultMessage: 'Upload company logo',
        description: 'Label for logo upload button'
    },
    logoUrlLabel: {
        defaultMessage: 'Company Logo Url (must be stored in Thrive CDN)',
        description: 'Label for logo url'
    },
    cdnLinkRequired: {
        defaultMessage: 'Url must link to an image stored in the Thrive CDN.',
        description:
            'Error message shown if company logo url is not to a cdn asset'
    }
})

export const LogoInput = ({ logoUrl, type, setLogoUrl }: LogoInputProps) => {
    const theme = useTheme()
    const intl = useIntl()

    const [logoModalOpen, setLogoModalOpen] = React.useState(false)

    return (
        <>
            <Stack spacing={3} data-testid="logo-input">
                <CoreTypography variant="h5">
                    <FormattedMessage
                        defaultMessage="Upload company logo (Optional)"
                        description="Description for upload company logo"
                    />
                </CoreTypography>
                <Stack
                    direction="row"
                    spacing={2}
                    sx={{ display: 'flex', alignItems: 'center' }}
                >
                    <Box
                        sx={{
                            minHeight: '100px',
                            minWidth: '100px',
                            maxHeight: '100px',
                            maxWidth: '100px',
                            border: `2px dashed ${theme.palette.divider}`,
                            borderRadius: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            img: {
                                height: '100%',
                                width: '100%',
                                objectFit: 'contain'
                            }
                        }}
                    >
                        {logoUrl && (
                            <img
                                data-testid="logo-preview"
                                src={logoUrl}
                                alt={intl.formatMessage(
                                    messages.logoPreviewLabel
                                )}
                            />
                        )}
                        {!logoUrl && <LeafIcon icon={'plus'} />}
                    </Box>
                    <Button
                        size="large"
                        variant="outlined"
                        color="primary"
                        onClick={() => setLogoModalOpen(true)}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            {!logoUrl ? (
                                <FormattedMessage
                                    description="Label for add button"
                                    defaultMessage="Add logo"
                                />
                            ) : (
                                <FormattedMessage
                                    description="Label for edit button"
                                    defaultMessage="Edit logo"
                                />
                            )}
                        </CoreTypography>
                    </Button>
                    {logoUrl && (
                        <Button
                            size="large"
                            variant="contained"
                            color="primary"
                            onClick={() => setLogoUrl('')}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for remove button"
                                    defaultMessage="Remove"
                                />
                            </CoreTypography>
                        </Button>
                    )}
                </Stack>
            </Stack>
            <LogoModal
                type={type}
                logoUrl={logoUrl}
                setLogoUrl={setLogoUrl}
                logoModalOpen={logoModalOpen}
                setLogoModalOpen={setLogoModalOpen}
            />
        </>
    )
}
