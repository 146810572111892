import { Company } from '../graphql/generated/autogenerated'

export enum ModalButtonVariant {
    Edit = 'edit',
    Add = 'add',
    Default = 'default',
    Icon = 'icon',
    Settings = 'settings'
}

export enum Variant {
    Edit = 'edit',
    Add = 'add',
    Default = 'default'
}

export interface CompanyWithIdpInfo extends Company {
    enabledIdpTypes: Array<string>
    enabledCompanyIdpIds: Array<string>
    idpTypeSearchString: string
    workspaceIdSearchString: string
    tenantIdSearchString: string
}
