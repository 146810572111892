import React, { useEffect } from 'react'
import { Box, Divider } from '@mui/material'
import { ModalButtonVariant } from '../../../../types'
import { ModalButton } from '../../../../components/buttons'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { ContentPlaceholder } from '../../../../components/elements'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { SignupCode } from '../../../../graphql/generated/autogenerated'
import { AddSignupCodeModal } from './AddSignupCodeModal/AddSignupCodeModal'
import { EditSignupCodeModal } from './EditSignupCodeModal/EditSignupCodeModal'
import { SignupCodesList } from '../../../../components/lists/SignupCodeList/SignupCodeList'

const messages = defineMessages({
    addSignupCodeLabel: {
        description: 'Label for add signup code button',
        defaultMessage: 'Add sign-up codes'
    },
    noSignupCodesLabel: {
        description: 'Text shown when a subscription has no signup codes',
        defaultMessage: 'No sign-up codes added for this subscription.'
    },
    noSubscriptionSignupCodesLabel: {
        description:
            'Text shown when no subscription exists to add signup codes to',
        defaultMessage: 'Create a subscription to add sign-up codes.'
    }
})

export interface CreateSignupCodesProps {
    subscriptionId?: string
    subscriptionName?: string
    signupCodes?: Partial<SignupCode>[]
}
const CreateSignupCodes: React.FC<CreateSignupCodesProps> = ({
    subscriptionId,
    subscriptionName,
    signupCodes
}) => {
    const intl = useIntl()

    useEffect(() => {
        window.scrollTo(0, 0)
    }, [])

    return (
        <Box data-testid="create-signup-codes">
            <Box
                sx={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'space-between',
                    alignItems: 'center'
                }}
            >
                <Box>
                    <CoreTypography id="signup-code-table-header" variant="h4">
                        {subscriptionName ? (
                            <FormattedMessage
                                description="Label for signup code table with subscription name"
                                defaultMessage="Sign-up codes for {name} subscription"
                                values={{ name: subscriptionName }}
                            />
                        ) : (
                            <FormattedMessage
                                description="Label for signup code table"
                                defaultMessage="Sign-up codes"
                            />
                        )}
                    </CoreTypography>
                </Box>
                <ModalButton
                    modalButtonVariant={ModalButtonVariant.Add}
                    ModalComponent={AddSignupCodeModal}
                    buttonText={intl.formatMessage(messages.addSignupCodeLabel)}
                    disabled={!subscriptionId}
                    trigger={{
                        subscriptionId: subscriptionId,
                        signupCodes: signupCodes
                    }}
                />
            </Box>
            <Divider />
            {signupCodes && (
                <SignupCodesList
                    signupCodes={signupCodes}
                    EditModal={EditSignupCodeModal}
                />
            )}
            {!signupCodes?.length && (
                <>
                    {subscriptionId ? (
                        <ContentPlaceholder
                            message={intl.formatMessage(
                                messages.noSignupCodesLabel
                            )}
                        />
                    ) : (
                        <ContentPlaceholder
                            message={intl.formatMessage(
                                messages.noSubscriptionSignupCodesLabel
                            )}
                        />
                    )}
                </>
            )}
        </Box>
    )
}

export default CreateSignupCodes
