import { FormattedMessage } from 'react-intl'
import React, { useCallback, useMemo, useState } from 'react'
import {
    Company,
    CompanyStatus
} from '../../../graphql/generated/autogenerated'
import { CompanyListItem } from '../../list-items'
import { ConfirmationSnackBar } from '../../features'
import { Box, Grid, Stack, Skeleton } from '@mui/material'
import { getComparator, Order } from '../../../utils/sort'
import { DebouncedCompanySort } from '../../elements/DebouncedCompanySort/DebouncedCompanySort'
import { DebouncedCompanyFilter } from '../../elements/DebouncedCompanyFilter/DebouncedCompanyFilter'
import { DebouncedCompanySearch } from '../../elements/DebouncedCompanySearch/DebouncedCompanySearch'
import { useAppDispatch, useAppSelector } from '@thriveglobal/thrive-web-core'
import {
    SearchState,
    setOrder,
    setOrderBy,
    setSearch,
    setSearchBy,
    setStatusFilters
} from '../../../slices/createCompany'
import { CompanyWithIdpInfo } from '../../../types'

const skeletonArray = [1, 2, 3, 4, 5, 6, 7]

interface CompanyListProps {
    companies: CompanyWithIdpInfo[]
}

export const CompanyList: React.FC<CompanyListProps> = ({ companies }) => {
    const dispatch = useAppDispatch()
    //memoize dispatch so the reference is stable
    const memoizedDispatch = useCallback(
        (args) => {
            dispatch(args)
        },
        [dispatch]
    )
    const [loading, setLoading] = useState(false)
    const [copiedId, setCopiedId] = useState<string>('')
    const [idConfirmationOpen, setIdConfirmationOpen] = useState<boolean>(false)

    const reduxState = useAppSelector((state) => state.createCompany)
    const { search, searchBy, order, orderBy, statusFilters } =
        reduxState?.searchState as SearchState

    const companiesArray = useMemo(
        () => companies.slice().sort(getComparator('asc', 'name')),
        [companies]
    )
    //search
    const updateSearch = useCallback(
        (value: string) => {
            memoizedDispatch(setSearch(value))
        },
        [memoizedDispatch]
    )

    const updateSearchBy = useCallback(
        (value: keyof Company) => {
            memoizedDispatch(setSearchBy(value))
        },
        [memoizedDispatch]
    )

    const updateOrder = useCallback(
        (value: Order) => {
            memoizedDispatch(setOrder(value))
        },
        [memoizedDispatch]
    )

    const updateOrderBy = useCallback(
        (value: keyof Company) => {
            memoizedDispatch(setOrderBy(value))
        },
        [memoizedDispatch]
    )

    const updateStatusFilters = useCallback(
        (value: CompanyStatus[]) => {
            memoizedDispatch(setStatusFilters(value))
        },
        [memoizedDispatch]
    )

    const showCopyConfirmation = (id: string) => {
        setCopiedId(id)
        setIdConfirmationOpen(true)
    }
    const handleCopyIdConfirmationClose = () => {
        setIdConfirmationOpen(false)
    }

    const filteredCompanies = useMemo(() => {
        if (search !== '') {
            return companiesArray.filter((company) => {
                return (
                    statusFilters.includes(company.status) &&
                    company[searchBy]
                        .toLowerCase()
                        .includes(search.toLowerCase())
                )
            })
        } else {
            return companiesArray.filter((company) =>
                statusFilters.includes(company.status)
            )
        }
    }, [search, statusFilters, searchBy, companiesArray])

    const sortedFilteredCompanies = useMemo(() => {
        return filteredCompanies.slice().sort(getComparator(order, orderBy))
    }, [filteredCompanies, order, orderBy])

    const getCompanyList = useMemo(() => {
        return sortedFilteredCompanies.map((company) => (
            <Grid item xs={12} key={company.id}>
                <CompanyListItem
                    company={company}
                    showCopyConfirmation={showCopyConfirmation}
                />
            </Grid>
        ))
    }, [sortedFilteredCompanies])

    return (
        <Box data-testid="company-list">
            <Grid container spacing={2} sx={{ mb: 5 }}>
                <Grid item xs={12} lg={7}>
                    <DebouncedCompanySearch
                        search={search}
                        searchBy={searchBy}
                        setSearch={updateSearch}
                        setSearchBy={updateSearchBy}
                        setLoading={setLoading}
                    />
                </Grid>
                <Grid item xs={12} lg={5}>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: { lg: 'end', md: 'start' },
                            alignItems: 'center'
                        }}
                    >
                        <DebouncedCompanySort
                            order={order}
                            orderBy={orderBy}
                            setOrder={updateOrder}
                            setOrderBy={updateOrderBy}
                            setLoading={setLoading}
                        />
                        <Box sx={{ ml: 2 }}>
                            <DebouncedCompanyFilter
                                statusFilters={statusFilters}
                                setStatusFilters={updateStatusFilters}
                                setLoading={setLoading}
                            />
                        </Box>
                    </Box>
                </Grid>
            </Grid>
            {loading && (
                <Grid item xs={12} data-testid="loading-skeleton">
                    <Stack spacing={2}>
                        {skeletonArray.map((el, index) => (
                            <Skeleton
                                key={`skel-${index}`}
                                variant="rectangular"
                                width="100%"
                                animation="pulse"
                                height={127}
                            />
                        ))}
                    </Stack>
                </Grid>
            )}
            {!loading && (
                <Grid container spacing={3}>
                    {getCompanyList}
                </Grid>
            )}
            <ConfirmationSnackBar
                isOpen={idConfirmationOpen}
                handleClose={handleCopyIdConfirmationClose}
                message={
                    <>
                        <FormattedMessage
                            description="Text for snackbar confirming company id was copied to clipboard"
                            defaultMessage={`Company ID: "{id}" copied to clipboard`}
                            values={{
                                id: copiedId
                            }}
                        />
                    </>
                }
            />
        </Box>
    )
}
