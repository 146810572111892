import React from 'react'
import {
    CoreTypography,
    LeafCircularProgress
} from '@thriveglobal/thrive-web-leafkit'
import { DialogContent, Stack } from '@mui/material'

interface ModalLoadingContentProps {
    displayText?: string
}

export const ModalLoadingContent: React.FC<ModalLoadingContentProps> = ({
    displayText
}) => {
    return (
        <DialogContent
            sx={{
                height: '100%',
                minHeight: '400px',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center'
            }}
        >
            <Stack
                spacing={2}
                sx={{
                    display: 'flex',
                    justifyContent: 'center',
                    maxWidth: '200px',
                    textAlign: 'center',
                    alignItems: 'center'
                }}
            >
                <LeafCircularProgress />
                {displayText && (
                    <CoreTypography variant="body2">
                        {displayText}
                    </CoreTypography>
                )}
            </Stack>
        </DialogContent>
    )
}
