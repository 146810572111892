import React, { ReactNode } from 'react'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { FormControlLabel, Checkbox, Stack, TextField } from '@mui/material'
import { Controller, useFormContext } from 'react-hook-form'
import { LicenseTypeSelector } from '../LicenseTypeSelector/LicenseTypeSelector'
import { SubscriptionTypeSelector } from '../SubscriptionTypeSelector/SubscriptionTypeSelector'
import {
    LicenseType,
    SubscriptionType
} from '../../../graphql/generated/autogenerated'
import { SubscriptionStatusSelector } from '../SubscriptionStatusSelector/SubscriptionStatusSelector'

const messages = defineMessages({
    subscriptionNameInputLabel: {
        defaultMessage: 'Name',
        description: 'Label for subscription name input'
    },
    subscriptionNameRequiredLabel: {
        defaultMessage: 'Subscription name is required.',
        description: 'Label for subscription name required error'
    },
    defaultSubscriptionLabel: {
        defaultMessage: 'Use as default subscription',
        description: 'Label default subscription checkbox'
    },
    subscriptionNameExistsLabel: {
        defaultMessage: 'A subscription with this name already exists.',
        description: 'Label for existing subscription name error'
    }
})

export interface SubscriptionFormInputs {
    name: string
    isDefault: boolean
    enabled: Number
    subscriptionType: SubscriptionType
    license: LicenseType
}

interface SubscriptionInputProps {
    canSetDefault?: boolean
    getAlreadyExists?: null | ((value: string) => boolean)
}

export const SubscriptionInput: React.FC<SubscriptionInputProps> = ({
    canSetDefault = false,
    getAlreadyExists = null
}) => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <Stack direction="column" spacing={2}>
            <Controller
                render={({ field }) => (
                    <TextField
                        fullWidth
                        required
                        spellCheck="false"
                        id="subscription-name-input"
                        variant="outlined"
                        label={intl.formatMessage(
                            messages.subscriptionNameInputLabel
                        )}
                        value={field.value}
                        inputRef={field.ref}
                        onChange={field.onChange}
                        error={!!errors?.name}
                        helperText={<>{errors?.name?.message}</>}
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                name="name"
                rules={{
                    required: intl.formatMessage(
                        messages.subscriptionNameRequiredLabel
                    ),
                    validate: {
                        notJustWhiteSpace: (value) => {
                            if (!value.trim())
                                return intl.formatMessage(
                                    messages.subscriptionNameRequiredLabel
                                )
                        },
                        doesntAlreadyExist: (value) => {
                            return getAlreadyExists && getAlreadyExists(value)
                                ? intl.formatMessage(
                                      messages.subscriptionNameExistsLabel
                                  )
                                : null
                        }
                    }
                }}
                control={control}
            />
            <SubscriptionTypeSelector />
            <LicenseTypeSelector />
            <FormControlLabel
                disabled={!canSetDefault}
                control={
                    <Controller
                        render={({ field }) => (
                            <Checkbox
                                disabled={!canSetDefault}
                                id="subscription-default-input"
                                checked={field.value}
                                inputRef={field.ref}
                                onChange={field.onChange}
                            />
                        )}
                        name="isDefault"
                        control={control}
                    />
                }
                label={intl.formatMessage(messages.defaultSubscriptionLabel)}
            />
            <SubscriptionStatusSelector />
        </Stack>
    )
}
