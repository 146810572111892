import React, { ReactNode } from 'react'
import { TextField } from '@mui/material'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'

const messages = defineMessages({
    idpIdRequiredError: {
        defaultMessage: 'Idp ID is required.',
        description: 'Error text for required tenant ID input'
    },
    ipdIdHelperText: {
        defaultMessage: 'Enter Idp ID to enable SSO',
        description: 'Helper text for sso idp id input'
    },
    idpIdInputLabel: {
        defaultMessage: 'Idp ID',
        description: 'Label for sso idp id input'
    }
})

export interface IdpIdFormInputs {
    idpId: string
}

export const IdpIdInput: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <>
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        spellCheck="false"
                        id="idp-id"
                        variant="outlined"
                        inputRef={field.ref}
                        value={field.value}
                        error={!!errors?.idpId}
                        onChange={field.onChange}
                        label={intl.formatMessage(messages.idpIdInputLabel)}
                        helperText={
                            <>
                                {errors?.idpId?.message
                                    ? errors?.idpId?.message
                                    : intl.formatMessage(
                                          messages.ipdIdHelperText
                                      )}
                            </>
                        }
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                control={control}
                rules={{
                    required: intl.formatMessage(messages.idpIdRequiredError),
                    validate: {
                        notJustWhiteSpace: (value) => {
                            if (!value.trim())
                                return intl.formatMessage(
                                    messages.idpIdRequiredError
                                )
                        }
                    }
                }}
                name="idpId"
            />
        </>
    )
}
