import { Stack, TextField } from '@mui/material'
import React, { ReactNode } from 'react'
import { defineMessages, useIntl } from 'react-intl'
import { useFormContext, Controller } from 'react-hook-form'

const messages = defineMessages({
    deprovisionReasonInputLabel: {
        defaultMessage: 'Reason for deprovisioning',
        description: 'Label deprovision reason input'
    },
    deprovisionReasonRequiredLabel: {
        defaultMessage: 'A reason for deprovisioning is required.',
        description: 'Label for nice tenant ID required error'
    },
    tenantIdInvalidLabel: {
        defaultMessage: 'Invalid Nice Tenant Id.',
        description: 'Label for nice tenant ID required error'
    },
    deprovisionReasonHelperText: {
        defaultMessage: 'Example: "IDENTITY-1234: Customer churned."',
        description: 'Helper text for deprovision reason input'
    }
} as const)

export interface DeprovisionReasonFormInputs {
    deprovisionReason: string
}

export const DeprovisionReasonInput: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <Stack spacing={2}>
            <Controller
                render={({ field }) => (
                    <TextField
                        required
                        fullWidth
                        spellCheck="false"
                        id="deprovision-reason"
                        variant="outlined"
                        inputRef={field.ref}
                        value={field.value}
                        error={!!errors?.deprovisionReason}
                        onChange={field.onChange}
                        label={intl.formatMessage(
                            messages.deprovisionReasonInputLabel
                        )}
                        helperText={
                            <>
                                {errors?.deprovisionReason
                                    ? errors?.deprovisionReason?.message
                                    : intl.formatMessage(
                                          messages.deprovisionReasonHelperText
                                      )}
                            </>
                        }
                        FormHelperTextProps={{
                            variant: 'standard'
                        }}
                    />
                )}
                control={control}
                rules={{
                    required: intl.formatMessage(
                        messages.deprovisionReasonRequiredLabel
                    ),
                    validate: {
                        notJustWhiteSpace: (value) => {
                            if (!value.trim())
                                return intl.formatMessage(
                                    messages.deprovisionReasonRequiredLabel
                                )
                        }
                    }
                }}
                name="deprovisionReason"
            />
        </Stack>
    )
}

export default React.memo(DeprovisionReasonInput)
