import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Stack
} from '@mui/material'
import {
    CoreAlert,
    CoreTypography,
    LoadingButton
} from '@thriveglobal/thrive-web-leafkit'
import React, { useRef, useState } from 'react'
import { uploadImage } from '../../../utils/logo'
import { LogoUpload } from '../LogoUpload/LogoUpload'
import { FormattedMessage, defineMessages, useIntl } from 'react-intl'
import { useInititateDirectUploadMutation } from '../../../graphql/generated/autogenerated'

const messages = defineMessages({
    logoModalTitle: {
        defaultMessage: 'Company Logo',
        description: 'Title for modal to upload company logo'
    },
    logoUploadError: {
        defaultMessage: 'There was an error uploading your image.',
        description: 'Title for error message on profile image upload'
    },
    savingChangesLabel: {
        defaultMessage: 'Saving changes.',
        description: 'Label for loading button when saving changes'
    }
})

export interface LogoModalProps {
    logoUrl?: string
    logoModalOpen: boolean
    type: 'company' | 'brand'
    setLogoUrl: (url: string) => void
    setLogoModalOpen: (bool: boolean) => void
}

export const LogoModal: React.FC<LogoModalProps> = ({
    type,
    logoUrl,
    setLogoUrl,
    logoModalOpen,
    setLogoModalOpen
}) => {
    const intl = useIntl()

    const uploadButtonRef = useRef(null)
    const transformedImageCanvasRef = useRef<HTMLCanvasElement>(null)

    const [scale, setScale] = useState(1)
    const [imgSrc, setImgSrc] = useState(logoUrl)
    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<boolean>(false)

    const handleClose = () => {
        setLogoModalOpen(false)
        setScale(1)
        setImgSrc('')
        setLoading(false)
    }

    const handleCancelClick = () => {
        handleClose()
    }

    const [uploadLogoImage] = useInititateDirectUploadMutation({
        onCompleted: async (data) => {
            const uploadUrl = data?.identity?.user?.initiateDirectUpload
            if (uploadUrl) {
                if (!transformedImageCanvasRef.current) {
                    setError(true)
                    setLoading(false)
                    return
                }

                try {
                    //await the Blob's creation before calling uploadImage
                    const blob = (await new Promise((resolve, reject) => {
                        transformedImageCanvasRef.current.toBlob((blob) => {
                            if (!blob) {
                                reject(
                                    new Error(
                                        'Failed to create blob from cropped image'
                                    )
                                )
                            } else {
                                resolve(blob)
                            }
                        })
                    })) as Blob

                    const uploadedImageUrl = await uploadImage(uploadUrl, blob)
                    if (uploadedImageUrl) {
                        setLogoUrl(uploadedImageUrl)
                        handleClose()
                    }
                } catch (error) {
                    setError(true)
                    setLoading(false)
                }
            }
        },
        onError: () => {
            setLoading(false)
            setError(true)
        }
    })

    const handleChangePhotoClick = () => {
        uploadButtonRef.current.click()
    }

    const handleSaveClick = () => {
        setLoading(true)
        uploadLogoImage()
    }

    const reader = new FileReader()
    reader.addEventListener('load', () => {
        setImgSrc(reader.result?.toString() || '')
    })

    return (
        <Dialog open={logoModalOpen} fullWidth onClose={handleClose}>
            <DialogTitle>
                <CoreTypography variant="h3">
                    {intl.formatMessage(messages.logoModalTitle)}
                </CoreTypography>
            </DialogTitle>
            <DialogContent>
                {error && (
                    <CoreAlert severity="error" sx={{ mb: 2 }}>
                        <CoreTypography variant="h5">
                            {intl.formatMessage(messages.logoUploadError)}
                        </CoreTypography>
                    </CoreAlert>
                )}
                <LogoUpload
                    scale={scale}
                    imgSrc={imgSrc}
                    type={type}
                    setScale={setScale}
                    setImgSrc={setImgSrc}
                    transformedImageCanvasRef={transformedImageCanvasRef}
                    uploadButtonRef={uploadButtonRef}
                />
            </DialogContent>
            <Divider />
            <DialogActions>
                <Stack direction="column" spacing={3} sx={{ width: '100%' }}>
                    <Box
                        sx={{
                            display: 'flex',
                            width: '100%',
                            justifyContent: imgSrc ? 'space-between' : 'right'
                        }}
                    >
                        {imgSrc && (
                            <Box>
                                <Button
                                    color="primary"
                                    onClick={handleChangePhotoClick}
                                    size="medium"
                                    variant="outlined"
                                >
                                    <CoreTypography customVariant="buttonNormal">
                                        <FormattedMessage
                                            description="Text for button to change photo chosen for profile image"
                                            defaultMessage="Change photo"
                                        />
                                    </CoreTypography>
                                </Button>
                            </Box>
                        )}
                        <Stack direction="row" spacing={1}>
                            <Button
                                color="info"
                                onClick={handleCancelClick}
                                size="medium"
                                variant="contained"
                            >
                                <CoreTypography customVariant="buttonNormal">
                                    <FormattedMessage
                                        description="Text for button to cancel profile image changes"
                                        defaultMessage="Cancel"
                                    />
                                </CoreTypography>
                            </Button>
                            <LoadingButton
                                color="primary"
                                fixWidth
                                loader={null}
                                disabled={loading}
                                loading={loading}
                                aria-label={
                                    loading
                                        ? intl.formatMessage(
                                              messages.savingChangesLabel
                                          )
                                        : null
                                }
                                variant="contained"
                                onClick={handleSaveClick}
                                size="medium"
                            >
                                <CoreTypography customVariant="buttonNormal">
                                    <FormattedMessage
                                        description="Text for button to save profile image changes"
                                        defaultMessage="Save changes"
                                    />
                                </CoreTypography>
                            </LoadingButton>
                        </Stack>
                    </Box>
                </Stack>
            </DialogActions>
        </Dialog>
    )
}
