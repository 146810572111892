import React from 'react'
import { FormattedMessage } from 'react-intl'
import {
    Table,
    TableCell,
    TableRow,
    TableHead,
    TableBody,
    TableContainer
} from '@mui/material'
import { useTheme } from '@thriveglobal/thrive-web-leafkit'

export interface SignupMethodListProps {
    children?: React.ReactNode
}

export const SignupMethodList: React.FC<SignupMethodListProps> = ({
    children
}) => {
    const theme = useTheme()
    return (
        <TableContainer>
            <Table
                data-testid="signup-method-list"
                aria-labelledby="signnup-method-table-header"
            >
                <TableHead>
                    <TableRow>
                        <TableCell
                            sx={{
                                backgroundColor: theme.palette.secondary.dark,
                                color: theme.palette.secondary.contrastText
                            }}
                        >
                            <FormattedMessage
                                description="Label for platform"
                                defaultMessage="Platform"
                            />
                        </TableCell>
                        <TableCell
                            sx={{ backgroundColor: theme.palette.grey[100] }}
                        >
                            <FormattedMessage
                                description="Label for method"
                                defaultMessage="Method"
                            />
                        </TableCell>
                        <TableCell
                            sx={{ backgroundColor: theme.palette.grey[100] }}
                        >
                            <FormattedMessage
                                description="Label for friends and family"
                                defaultMessage="F&F Only"
                            />
                        </TableCell>
                        <TableCell
                            size="small"
                            sx={{ backgroundColor: theme.palette.grey[100] }}
                        ></TableCell>
                        <TableCell
                            sx={{ backgroundColor: theme.palette.grey[100] }}
                        >
                            <FormattedMessage
                                description="Label for Sign-up methods priority"
                                defaultMessage="Priority"
                            />
                        </TableCell>
                        <TableCell
                            sx={{ backgroundColor: theme.palette.grey[100] }}
                        >
                            <FormattedMessage
                                description="Label for status"
                                defaultMessage="Status"
                            />
                        </TableCell>
                        <TableCell
                            sx={{ backgroundColor: theme.palette.grey[100] }}
                        ></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>{children}</TableBody>
            </Table>
        </TableContainer>
    )
}
