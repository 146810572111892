import React, { useMemo } from 'react'
import { Grid, Button, Box } from '@mui/material'
import { CreateCompanyTabs } from '../CreateCompany'
import { TabBarNav } from '../../../components/tabs'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import CreationCallback from '../CreationCallback/CreationCallback'
import { EmailDomainListItem } from '../../../components/list-items'
import {
    EmailDomainInput,
    getDomainsArray,
    EmailDomainFormInputs
} from '../../../components/inputs/EmailDomainInput/EmailDomainInput'
import { useForm, SubmitHandler, FormProvider } from 'react-hook-form'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { addEmailDomain, setActiveTab } from '../../../slices/createCompany'
import { useAppSelector, useAppDispatch } from '@thriveglobal/thrive-web-core'

const messages = defineMessages({
    addEmailDomainLabel: {
        defaultMessage: 'Add email domain',
        description: 'Label for button to add email domain'
    }
})

const EmailDomains: React.FC = () => {
    const intl = useIntl()
    const dispatch = useAppDispatch()
    const [creationInProgress, setCreationInProgress] = React.useState(false)

    const formMethods = useForm<EmailDomainFormInputs>({
        defaultValues: { domains: '' },
        reValidateMode: 'onSubmit'
    })

    const createCompany = useAppSelector((state) => state.createCompany)
    const emailDomains = createCompany?.emailDomains

    const handleAddEmailDomainClick: SubmitHandler<EmailDomainFormInputs> = (
        data
    ) => {
        const domains = getDomainsArray(data.domains)
        dispatch(addEmailDomain(domains))
        formMethods.reset()
    }

    const handleNextClick = () => {
        setCreationInProgress(true)
    }

    const handleBackClick = () => {
        dispatch(setActiveTab(CreateCompanyTabs.SubscriptionsAndCodes))
    }

    return creationInProgress ? (
        <CreationCallback />
    ) : (
        <>
            <Grid item xs={12} data-testid="email-domains-section">
                <CoreTypography variant={'h3'}>
                    <FormattedMessage
                        description="Heading for Email Domains section"
                        defaultMessage="Email Domains"
                    />
                </CoreTypography>
            </Grid>
            <Grid item xs={12}>
                <CoreTypography variant="h5">
                    <FormattedMessage
                        description="Heading for Email Domains section"
                        defaultMessage="Restrict sign-ups by email domain (Optional)"
                    />
                </CoreTypography>
            </Grid>
            <Grid item sx={{ pt: '8px !important' }}>
                <CoreTypography variant="body2">
                    <FormattedMessage
                        description="Add email domain instructions"
                        defaultMessage="To add multiple email domains at once, separate each domain with a comma."
                    />
                </CoreTypography>
            </Grid>
            <Grid item xs={12} md={7}>
                <Box
                    component="form"
                    noValidate
                    autoComplete="off"
                    sx={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between'
                    }}
                >
                    <FormProvider {...formMethods}>
                        <EmailDomainInput />
                    </FormProvider>
                    <Button
                        variant="outlined"
                        color="primary"
                        onClick={formMethods.handleSubmit(
                            handleAddEmailDomainClick
                        )}
                        aria-label={intl.formatMessage(
                            messages.addEmailDomainLabel
                        )}
                        sx={{ ml: 2 }}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to add email domain"
                                defaultMessage="Add"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            </Grid>
            <Grid item xs={12} md={6}>
                {emailDomains?.map((domain) => (
                    <EmailDomainListItem emailDomain={domain} key={domain} />
                ))}
            </Grid>
            <Grid item xs={12}>
                <TabBarNav
                    handleNextClick={handleNextClick}
                    handleBackClick={handleBackClick}
                    isFirstTab={false}
                    isFinalTab={true}
                />
            </Grid>
        </>
    )
}

export default EmailDomains
