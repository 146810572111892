import { gql } from '@thriveglobal/thrive-web-core'

export const GET_ALL_IDPCONFIGS = gql`
    query getAllIdpConfigs {
        identity {
            idpConfig {
                getAll(limit: 10000) {
                    items {
                        companyId
                        configuration
                        emailAllowList
                        id
                        idpType
                        loginMethod
                        enabled
                        idpCompanyId
                    }
                }
            }
        }
    }
`

export const GET_COMPANY_IDPCONFIGS = gql`
    query getCompanyIdpConfigs($configFilters: IdpConfigFiltersInput) {
        identity {
            idpConfig {
                getAll(filters: $configFilters) {
                    total
                    items {
                        loginMethod
                        idpType
                        emailAllowList
                        companyId
                        configuration
                        id
                        enabled
                        idpCompanyId
                    }
                }
            }
        }
    }
`

export const GET_IDP_ID_BY_COMPANY_ID = gql`
    query getIdpIdByCompanyId($idpCompanyId: String!, $idpType: IdpType!) {
        identity {
            idpConfig {
                tryGetByIdpCompanyId(
                    idpCompanyId: $idpCompanyId
                    idpType: $idpType
                ) {
                    id
                }
            }
        }
    }
`
