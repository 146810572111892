import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Box
} from '@mui/material'
import React from 'react'
import { useParams } from 'react-router-dom'
import { FormattedMessage } from 'react-intl'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { ModalProps } from '../../../../../../../components/buttons'
import { GET_COMPANY_IDPCONFIGS } from '../../../../../../../graphql/queries/IdpConfig'
import { useDisallowEmailDomainMutation } from '../../../../../../../graphql/generated/autogenerated'

export const DeleteEmailDomainModal: React.FC<ModalProps> = ({
    closeModal,
    trigger
}) => {
    const { id } = useParams()
    const [disallowEmailDomainMutation, { error }] =
        useDisallowEmailDomainMutation({
            variables: {
                emailDomain: trigger.domain,
                idpConfigId: trigger.idp.id
            },
            onCompleted: () => {
                closeModal()
            },
            refetchQueries: [
                {
                    query: GET_COMPANY_IDPCONFIGS,
                    variables: { configFilters: { companyId: id } }
                },
                'GetCompanyIdpConfigs'
            ]
        })

    const handleConfirmDeleteClick = () => {
        disallowEmailDomainMutation()
    }

    const handleCancelClick = () => {
        closeModal()
    }

    return (
        <>
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>
                <FormattedMessage
                    defaultMessage="Disallow Email Domain "
                    description="Title for delete email domain modal"
                />
            </DialogTitle>
            <DialogContent>
                {!error && (
                    <CoreTypography variant="body2">
                        <FormattedMessage
                            description="Label for text asking to confirm deletion"
                            defaultMessage="Are you sure you want to disallow {domain}?"
                            values={{
                                domain: trigger.domain
                            }}
                        />
                    </CoreTypography>
                )}
                {/* TODO: more descriptive errors */}
                {error && (
                    <CoreTypography variant="body2" sx={{ color: 'red' }}>
                        <FormattedMessage
                            description="Email domain code deletion error text"
                            defaultMessage="There was an error with your request."
                        />
                        {error?.message}
                    </CoreTypography>
                )}
            </DialogContent>
            <DialogActions>
                {!error && (
                    <Box sx={{ p: 2 }}>
                        <Button
                            variant="contained"
                            color="secondary"
                            onClick={handleConfirmDeleteClick}
                            sx={{ mr: 2 }}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for button to disallow email domain"
                                    defaultMessage="Disallow"
                                />
                            </CoreTypography>
                        </Button>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleCancelClick}
                        >
                            <CoreTypography customVariant="buttonNormal">
                                <FormattedMessage
                                    description="Label for button to cancel edit"
                                    defaultMessage="Cancel"
                                />
                            </CoreTypography>
                        </Button>
                    </Box>
                )}
            </DialogActions>
        </>
    )
}
