import { getApolloClient } from '@thriveglobal/thrive-web-core'
import { GET_SIGNUP_CODE } from '../graphql/queries/companies'

export const companyNameExists = async (name: string) => {
    try {
        const query = await fetch(process.env.IDENTITY_PUBLIC, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify({
                query: `
          query get($name: String!) {
            getSimilarCompanies(name: $name) {
              items {
                quality
                result {
                  name
                }
              }
            }
          }
            `,
                variables: {
                    name: name
                }
            })
        })
        const result = await query.json()
        const exists = result.data?.getSimilarCompanies?.items?.find(
            (item) => item.quality > 0.8
        )

        return !!exists
    } catch (error) {
        return false
    }
}

export const signupCodeExists = async (name: string) => {
    try {
        const client = getApolloClient()
        const result = await client.query({
            query: GET_SIGNUP_CODE,
            variables: {
                signupCode: name
            }
        })
        const existsInDb = result?.data?.identity?.signupCode?.tryGet?.code
        return !!existsInDb
    } catch (error) {
        return false
    }
}
