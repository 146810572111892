import {
    Button,
    DialogTitle,
    DialogContent,
    DialogActions,
    Stack,
    Box
} from '@mui/material'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { FormProvider } from 'react-hook-form'
import { CoreAlert, CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import DeprovisionReasonInput from '../../inputs/DeprovisionReasonInput/DeprovisionReasonInput'

interface ConfirmDeprovisionProps {
    title: string
    description: string
    warning: string
    formMethods: any
    handleSubmit: any
    handleCancel: any
}
export const ConfirmDeprovision: React.FC<ConfirmDeprovisionProps> = ({
    title,
    description,
    warning,
    formMethods,
    handleSubmit,
    handleCancel
}) => {
    return (
        <Box
            sx={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between'
            }}
        >
            <DialogTitle sx={{ pt: 0, fontSize: '24px' }}>{title}</DialogTitle>
            <DialogContent>
                <Stack
                    spacing={3}
                    component="form"
                    id="deprovision-company-form"
                    onSubmit={handleSubmit}
                    noValidate
                >
                    <CoreTypography variant="body1">
                        {description}
                    </CoreTypography>
                    <CoreAlert severity="warning">
                        <CoreTypography variant="body1">
                            {warning}
                        </CoreTypography>
                    </CoreAlert>
                    <FormProvider {...formMethods}>
                        <DeprovisionReasonInput />
                    </FormProvider>
                </Stack>
            </DialogContent>
            <DialogActions>
                <Box sx={{ p: 2 }}>
                    <Button
                        variant="contained"
                        color="secondary"
                        onClick={handleCancel}
                        sx={{ mr: 2 }}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to go back in modal"
                                defaultMessage="Back"
                            />
                        </CoreTypography>
                    </Button>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        onClick={handleSubmit}
                    >
                        <CoreTypography customVariant="buttonNormal">
                            <FormattedMessage
                                description="Label for button to confirm update"
                                defaultMessage="Confirm"
                            />
                        </CoreTypography>
                    </Button>
                </Box>
            </DialogActions>
        </Box>
    )
}
