import React from 'react'
import { ModalButton } from '../../buttons'
import { ModalButtonVariant } from '../../../types'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { FormattedMessage, useIntl, defineMessages } from 'react-intl'
import { Table, TableCell, TableRow, TableHead, TableBody } from '@mui/material'
import { DeleteEmailDomainModal } from '../../../pages/Company/IdentitySettings/SignupMethodsAndEmailDomains/EmailDomains/EmailDomainModals/DeleteEmailDomainModal/DeleteEmailDomainModal'

export interface EmailDomainsListProps {
    idp: any
    domains: string[]
}

const messages = defineMessages({
    disallowEmailDomainLabel: {
        defaultMessage: 'Disallow email domain',
        description: 'Label for button to disallow email domain'
    }
})

export const EmailDomainsList: React.FC<EmailDomainsListProps> = ({
    domains,
    idp
}) => {
    const intl = useIntl()
    return (
        <>
            <Table aria-labelledby="email-table-header">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <FormattedMessage
                                description="Label for email domain column"
                                defaultMessage="Email Domain"
                            />
                        </TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {domains.map((domain) => (
                        <TableRow
                            data-testid={`table-row-${domain}`}
                            key={domain}
                            sx={{
                                '&:last-child td, &:last-child th': {
                                    border: 0
                                }
                            }}
                        >
                            <TableCell>
                                <CoreTypography variant="overline">
                                    @{domain}
                                </CoreTypography>
                            </TableCell>
                            <TableCell
                                sx={{ display: 'flex', justifyContent: 'end' }}
                            >
                                <ModalButton
                                    modalButtonVariant={ModalButtonVariant.Icon}
                                    buttonLabel={intl.formatMessage(
                                        messages.disallowEmailDomainLabel
                                    )}
                                    iconName={'trash'}
                                    ModalComponent={DeleteEmailDomainModal}
                                    trigger={{ domain: domain, idp: idp }}
                                />
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>
        </>
    )
}
