import React from 'react'
import { IconButton } from '@mui/material'
import { useIntl, defineMessages } from 'react-intl'
import { LeafIcon } from '@thriveglobal/thrive-web-leafkit'

const messages = defineMessages({
    copyIdLabel: {
        defaultMessage: 'Copy company id to clipboard',
        description: 'Label for button to copy company id to clipboard'
    }
})

export interface CopyIdButtonProps {
    id: string
    showCopyConfirmation: (id: string) => void
}

export const CopyIdButton: React.FC<CopyIdButtonProps> = ({
    id,
    showCopyConfirmation
}) => {
    const intl = useIntl()
    const handleCopyIdClick = (id: string) => {
        navigator.clipboard.writeText(id)
        showCopyConfirmation(id)
    }
    return (
        <IconButton
            color="primary"
            aria-label={intl.formatMessage(messages.copyIdLabel)}
            onClick={() => handleCopyIdClick(id)}
        >
            <LeafIcon icon={'copy'} fontSize="small" />
        </IconButton>
    )
}
