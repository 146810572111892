import React from 'react'
import { useAppDispatch } from '@thriveglobal/thrive-web-core'
import { CoreTypography } from '@thriveglobal/thrive-web-leafkit'
import { useIntl, defineMessages, FormattedMessage } from 'react-intl'
import { ContentPlaceholder } from '../../../../../components/elements'
import { Divider, Tabs, styled, Tab, TabProps, Box } from '@mui/material'
import { setSelectedSubscriptionId } from '../../../../../slices/createCompany'
import { UserSubscription } from '../../../../../graphql/generated/autogenerated'

const StyledTab = styled((props: TabProps) => <Tab {...props} />)(
    ({ theme }) => ({
        textTransform: 'none',
        alignItems: 'start',
        textAlign: 'left',
        padding: '1rem',
        ...theme.typography.body1,
        color: theme.palette.text.primary,

        '&.Mui-selected': {
            backgroundColor: theme.palette.secondary.main,
            color: theme.palette.text.primary,
            fontWeight: '600'
        },
        '&.Mui-focusVisible': {
            backgroundColor: theme.palette.secondary.dark
        },
        '&.MuiButtonBase-root': {
            maxWidth: '800px'
        }
    })
)

const messages = defineMessages({
    noSubscriptionsLabel: {
        defaultMessage: 'No subscriptions added.',
        description: 'Label for create new button'
    },
    defaultDescriptor: {
        defaultMessage: '(default)',
        description:
            'Text to describe that a subscription is the default subscription'
    }
})

export interface SubscriptionProps {
    selectedSubscriptionId: string
    subscriptions: Partial<UserSubscription>[]
}

export const SubscriptionSelector: React.FC<SubscriptionProps> = ({
    selectedSubscriptionId,
    subscriptions
}) => {
    const intl = useIntl()
    const dispatch = useAppDispatch()

    const handleSubscriptionChange = (
        event: React.SyntheticEvent,
        id: Partial<UserSubscription>
    ) => {
        dispatch(setSelectedSubscriptionId(id))
    }

    return (
        <Box data-testid="subscriptions-selector">
            <Box sx={{ py: 2 }}>
                <CoreTypography variant="h4">
                    <FormattedMessage
                        description="Label for subscription table"
                        defaultMessage="Subscriptions"
                    />
                </CoreTypography>
            </Box>
            <Divider />
            {!!subscriptions.length && (
                <Tabs
                    orientation="vertical"
                    variant="scrollable"
                    value={selectedSubscriptionId}
                    onChange={handleSubscriptionChange}
                    sx={{ pt: 2 }}
                >
                    {subscriptions.map((sub) => (
                        <StyledTab
                            value={sub.id}
                            key={sub.id}
                            label={
                                <Box
                                    sx={{
                                        width: '100%',
                                        display: 'flex',
                                        justifyContent: 'space-between',
                                        alignItems: 'center'
                                    }}
                                >
                                    <CoreTypography>
                                        {sub.isDefault
                                            ? `${
                                                  sub.internalDescription
                                              } ${intl.formatMessage(
                                                  messages.defaultDescriptor
                                              )}`
                                            : sub.internalDescription}
                                    </CoreTypography>
                                </Box>
                            }
                            data-testid={`tab-${sub.internalDescription}`}
                        />
                    ))}
                </Tabs>
            )}
            {!subscriptions?.length && (
                <ContentPlaceholder
                    message={intl.formatMessage(messages.noSubscriptionsLabel)}
                />
            )}
        </Box>
    )
}
