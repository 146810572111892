import React, { ReactNode } from 'react'
import { useIntl, defineMessages } from 'react-intl'
import { Controller, useFormContext } from 'react-hook-form'
import { TextField, Grid, InputAdornment } from '@mui/material'

const messages = defineMessages({
    emailDomainsInputLabel: {
        defaultMessage: 'Email Domains',
        description: 'Label for email domains input'
    },
    addEmailDomainsLabel: {
        defaultMessage: 'Add email domains',
        description: 'Label for button to add email domains'
    },
    emailDomainRequired: {
        defaultMessage: 'Email domain is required.',
        description: 'Email domain required error'
    }
})

export interface EmailDomainFormInputs {
    domains: string
}

export const getDomainsArray = (str: string) => {
    //remove white space and trailing commas then split on commas
    return str.replace(/\s+/g, '').replace(/(,$)/g, '').split(',')
}

export const EmailDomainInput: React.FC = () => {
    const intl = useIntl()
    const {
        control,
        formState: { errors }
    } = useFormContext()

    return (
        <>
            <Grid item xs={12}>
                <Controller
                    render={({ field }) => (
                        <TextField
                            fullWidth
                            multiline
                            spellCheck="false"
                            id="email-domain-input"
                            variant="outlined"
                            value={field.value}
                            inputRef={field.ref}
                            onChange={field.onChange}
                            label={intl.formatMessage(
                                messages.emailDomainsInputLabel
                            )}
                            error={!!errors?.domains}
                            helperText={<>{errors?.domains?.message}</>}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        @
                                    </InputAdornment>
                                )
                            }}
                            FormHelperTextProps={{
                                variant: 'standard'
                            }}
                            sx={{
                                '& .MuiInputAdornment-positionStart': {
                                    mt: '0 !important'
                                }
                            }}
                        />
                    )}
                    name="domains"
                    control={control}
                    rules={{
                        required: intl.formatMessage(
                            messages.emailDomainRequired
                        ),
                        validate: {
                            notJustWhiteSpace: (value) => {
                                if (!value.trim())
                                    return intl.formatMessage(
                                        messages.emailDomainRequired
                                    )
                            }
                        }
                    }}
                />
            </Grid>
        </>
    )
}
